import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTheme } from '@material-ui/core';

import { Placeholders } from '../../assets/Placeholders';
import { ContentContext } from '../../context/ContentContext';
import { RouteEnum } from '../../enums/RouteEnum';
import { IExplorerParams } from '../../interfaces/IExplorerParams';
import { IPositioningInfo } from '../../interfaces/IPositioningInfo';
import Helpers from '../../services/Helpers';
import { CapColors } from '../../theme/CapColors';
import { CapFonts } from '../../theme/CapFonts';
import CapMuiTheme from '../../theme/CapMuiTheme';
import Chevron from '../../theme/Chevron';
import { CapButton } from '../atoms/CapButton';

export interface IBreadcrumbProps {
  display: string;
  url: string;
}

export const Breadcrumb = () => {
  const theme = useTheme();
  const params: IExplorerParams = useParams();
  const dataContext = useContext(ContentContext);
  const [trail, setTrail] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    const initAsync = async () => {
      if (params) {
        const system = await dataContext.getSystemAsync(
          params.systemSlug ?? ""
        );
        const concentration = system?.concentrations.find(
          (x) => x.slug == params.concentrationSlug
        );
        const focus = concentration?.focuses?.find(
          (x) => x.slug == params.focusSlug
        );
        const curTrail = Helpers.slugTrail(
          system?.displayName,
          concentration?.displayName ?? "",
          focus?.displayName ?? ""
        );
        const curUrl = Helpers.slugUrl(
          params.systemSlug ?? "",
          params.concentrationSlug ?? "",
          params.focusSlug,
          concentration?.sys.id ?? ""
        );
        setTrail(curTrail);
        setUrl(`/${RouteEnum.explore}/${curUrl}`);
      }
    };
    initAsync();
  }, []);

  return params ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: CapColors.background.blue,
        minHeight: CapMuiTheme.spacing(3),
        marginBottom: CapMuiTheme.spacing(4),
        paddingLeft: CapMuiTheme.spacing(2),
      }}
    >
      <Chevron
        className={""}
        // {classes.arrow}
        width={10}
        fill={CapColors.background.lightGrey}
      />
      {/* <Typography
        style={{
          color: CapColors.text.bodyNegative,
          fontSize: theme.typography.fontSize * 0.8,
          paddingLeft: CapMuiTheme.spacing(1),
        }}
      > */}
      <Link
        to={url ?? ""}
        style={{
          color: CapColors.text.bodyNegative,
          fontSize: theme.typography.fontSize * 0.8,
        }}
      >
        {trail}
      </Link>
      {/* </Typography> */}
    </div>
  ) : (
    <></>
  );
};
