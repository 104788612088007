/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Route, useLocation } from "react-router-dom";

import { Avatar, Button, Grid, Link, useMediaQuery } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { CapImages } from "../../assets/CapImages";
import { RouteEnum } from "../../enums/RouteEnum";
import CapMuiTheme from "../../theme/CapMuiTheme";
import { CapButton } from "../atoms/CapButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoNavBarHeaderRoot: {
      [theme.breakpoints.up("xs")]: {
        flexDirection: (props: IProps) => (props.footer ? "column" : "row"),
      },
      [theme.breakpoints.up("sm")]: {
        height: (props: IProps) => (props.footer ? 75 : 40),
        //TODO: Using conditional in xs forces use up the chain. Why?
        // flexDirection: 'row',
        flexDirection: (props: IProps) => (props.footer ? "row" : "row"),
      },
      [theme.breakpoints.up("md")]: {
        height: (props: IProps) => (props.footer ? 75 : 40),
      },
      [theme.breakpoints.up("xl")]: {
        height: (props: IProps) => (props.footer ? 75 : 40),
        marginBottom: CapMuiTheme.spacing(4),
      },
      paddingLeft: CapMuiTheme.spacing(2),
      paddingRight: CapMuiTheme.spacing(2),
    },
    logoNavBarFooterRoot: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: CapMuiTheme.spacing(2),
      paddingRight: CapMuiTheme.spacing(2),
    },
    capLogoWrapper: {
      [theme.breakpoints.up("xs")]: {
        flex: 1,
        marginBottom: (props: IProps) =>
          props.footer ? CapMuiTheme.spacing(0) : CapMuiTheme.spacing(2),
        justifyContent: "center",
      },
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flex: 0.1,
        alignItems: "center",
        marginBottom: CapMuiTheme.spacing(0),
      },
    },
    capLogo: {
      // height: '68px', //(props: IProps) => (props.footer ? '50px' : '60px'),
      [theme.breakpoints.up("xs")]: {
        height: (props: IProps) => (props.footer ? 50 : 80),
      },
      [theme.breakpoints.up("sm")]: {
        height: (props: IProps) => (props.footer ? 50 : 60),
      },
      [theme.breakpoints.up("md")]: {
        height: (props: IProps) => (props.footer ? 50 : 60),
      },
      [theme.breakpoints.up("xl")]: {
        height: (props: IProps) => (props.footer ? 50 : 68),
      },
      width: "auto",
      margin: 0,
      padding: 0,
    },
    ampLogoWrapper: {
      display: "flex",
      flex: 0,
    },
    ampLogo: {
      height: 50,
      margin: 0,
      padding: 0,
    },
    nyCreatesLogoWrapper: {
      display: "flex",
      flex: 0,
    },
    nyCreatesLogo: {
      height: 30,
      margin: 0,
      padding: 0,
    },
    sunyPolyLogoWrapper: {
      display: "flex",
      flex: 0,
    },
    sunyPolyLogo: {
      height: 60,
      margin: 0,
      padding: 0,
    },
    nordtechLogoWrapper: {
      display: "flex",
      flex: 0,
    },
    nordtechLogo: {
      height: 70,
      margin: 0,
      padding: 0,
    },
    catn2LogoWrapper: {
      display: "flex",
      flex: 0,
    },
    catn2Logo: {
      height: 60,
      margin: 0,
      padding: 0,
    },
    buttonWrapper: {
      [theme.breakpoints.up("xs")]: {
        justifyContent: "center",
        marginTop: (props: IProps) =>
          props.footer ? CapMuiTheme.spacing(1) : CapMuiTheme.spacing(0),
        marginBottom: (props: IProps) =>
          props.footer ? CapMuiTheme.spacing(2) : CapMuiTheme.spacing(0),
      },
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-end",
      },
      // justifyContent: 'flex-end',
      flex: (props: IProps) => (props.footer ? 1 : 1), // 1,
      alignItems: "center",
    },
  })
);

interface IProps {
  footer?: boolean | false;
  home?: boolean | false;
}

export const LogoNavBar = (props: IProps) => {
  const styleProps: IProps = props;
  const classes = useStyles(styleProps);
  const location = useLocation();
  const currentRoute = location.pathname.split("/")[1];
  const isExploreRoute =
    currentRoute == RouteEnum.explore ||
    currentRoute == RouteEnum.landing ||
    currentRoute == RouteEnum.content ||
    currentRoute == RouteEnum.contentDisplay;
  const mqry = {
    xs: useMediaQuery(CapMuiTheme.breakpoints.up("xs")),
    md: useMediaQuery(CapMuiTheme.breakpoints.up("md")),
    xl: useMediaQuery(CapMuiTheme.breakpoints.up("xl")),
  };
  const doSmall =
    (props.footer && mqry.md && !mqry.xl) || (mqry.xs && !mqry.md && !mqry.xl);
  return (
    <>
      <Grid
        container
        item
        className={
          props.footer
            ? classes.logoNavBarFooterRoot
            : classes.logoNavBarHeaderRoot
        }
      >
        <Grid container className={classes.capLogoWrapper}>
          <Link href="/">
            <img
              src={
                props.home || props.footer
                  ? CapImages.HeaderLogoWhite
                  : CapImages.HeaderLogoBlue
              }
              className={classes.capLogo}
            ></img>
          </Link>
        </Grid>
        {props.footer && (
          <Grid container className={classes.ampLogoWrapper}>
            <img src={CapImages.AmpLogo} className={classes.ampLogo}></img>
          </Grid>
        )}
        {props.footer && (
          <Grid container className={classes.ampLogoWrapper}>
            <img
              src={CapImages.NyCreatesLogo}
              className={classes.nyCreatesLogo}
            ></img>
          </Grid>
        )}
        {props.footer && (
          <Grid container className={classes.nordtechLogoWrapper}>
            <img
              src={CapImages.NordtechLogo}
              className={classes.nordtechLogo}
            ></img>
          </Grid>
        )}
        {props.footer && (
          <Grid container className={classes.ampLogoWrapper}>
            <img src={CapImages.Catn2Logo} className={classes.catn2Logo}></img>
          </Grid>
        )}
        {!props.footer && (
          <Grid container className={classes.buttonWrapper}>
            <CapButton
              active={currentRoute == RouteEnum.ampSkillup}
              home={props.home}
              footer={props.footer}
              small={doSmall}
              nav
              url={`/${RouteEnum.ampSkillup}`}
              icon={
                <img
                  src={
                    props.home
                      ? CapImages.AmpSkillupLogo
                      : CapImages.AmpSkillupLogoInverse
                  }
                  style={{
                    width: "auto",
                    height: 28,
                    paddingRight: CapMuiTheme.spacing(1),
                    transform: "skew(5deg)",
                    marginBottom: CapMuiTheme.spacing(0.6),
                  }}
                />
              }
            />
            <CapButton
              active={isExploreRoute}
              caption={"Explore"}
              uppercase
              home={props.home}
              footer={props.footer}
              small={doSmall}
              nav
              url={`/${RouteEnum.explore}/facilities-utilities`}
            />
            <CapButton
              active={currentRoute == RouteEnum.about}
              caption={"About"}
              uppercase
              home={props.home}
              footer={props.footer}
              small={doSmall}
              nav
              url={`/about`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
