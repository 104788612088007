import AmpLogo from "./static-images/AMP-Logo.png";
import Catn2Logo from "./static-images/CATN2-logo.png";
import FooterBackground from "./static-images/footer-background.jpg";
import HomeHeader from "./static-images/header-home.jpg";
import HeaderLandingPage from "./static-images/header-landing-page.jpg";
import HomeEquipment from "./static-images/home-equipment.jpg";
import HomeFacilities from "./static-images/home-facilities.jpg";
import HomeProcess from "./static-images/home-process.jpg";
import HeaderLogoBlue from "./static-images/logo-blue.png";
import HeaderLogoWhite from "./static-images/logo.png";
import NordtechLogo from "./static-images/NORDTECH_knockout.png";
import NyCreatesLogo from "./static-images/NY CREATES_logo only_white.png";
import AmpSkillupLogoInverse from "./static-images/skillup-transparent.png";
import AmpSkillupLogo from "./static-images/skillup-white-transparent.png";
import SunyPolyLogo from "./static-images/SUNY-Poly-logo-header.png";

export const CapImages = {
  AmpLogo,
  AmpSkillupLogo,
  AmpSkillupLogoInverse,
  Catn2Logo,
  FooterBackground,
  HeaderLandingPage,
  HeaderLogoBlue,
  HeaderLogoWhite,
  HomeEquipment,
  HomeFacilities,
  HomeHeader,
  HomeProcess,
  NordtechLogo,
  NyCreatesLogo,
  SunyPolyLogo,
};
