export enum PathName {
  concentrationId = "concentrationId",
  concentrationSlug = "concentrationSlug",
  contentId = "contentId",
  contentSlug = "contentSlug",
  courseId = "courseId",
  courseSlug = "courseSlug",
  focusId = "focusId",
  focusSlug = "focusSlug",
  systemSlug = "systemSlug",
}
