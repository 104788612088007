// import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
// import createPalette from '@material-ui/core/styles/createPalette';

// declare module '@material-ui/core/styles/createPalette' {
//     interface Palette {
//         buttonBackground: Palette['primary'];
//     }
//     interface PaletteOptions {
//         buttonBackground: PaletteOptions['primary'];
//     }
// }

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

let CapMuiTheme = createMuiTheme({
    spacing: 10, //mui default = 8
    breakpoints: {
        values: {
            // xs: 0,
            // sm: 600,
            // md: 1020,
            // lg: 1280,
            // xl: 1920,

            xs: 0,
            sm: 600,
            md: 768,
            lg: 992,
            xl: 1190,
        },
    },
    palette: {
        primary: {
            main: '#334ea1',
        },
        secondary: {
            main: '#eba900',
        },
        info: {
            main: '#939393',
        },
        background: {
            default: '#fff',
        },
        // buttonBackground: {
        //     main: capButtonBackground,
        // },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
                '&:hover, &:active, &:focus': {
                    backgroundColor: '#25b34b', //'#3cb74b', // '#eba900',
                },
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: '#eba900',
                    boxShadow: 'none',
                },
            },
            label: {
                textTransform: 'none',
            },
            outlined: {
                color: '#184b9f',
                borderColor: '#184b9f',
                '&:hover, &:active, &:focus': {
                    borderColor: '#eba900',
                },
                padding: '1px 15px',
            },
            text: {
                backgroundColor: 'transparent',
                transition: 'background-color .2s, color .2s',
                '&:hover, &:active, &:focus': {
                    color: '#fff',
                    backgroundColor: '#25b34b',
                },
            },
            textPrimary: {
                backgroundColor: 'transparent',
                transition: 'background-color .2s, color .2s',
                '&:hover, &:active, &:focus': {
                    color: '#fff',
                    backgroundColor: '#eba900',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                color: '#184b9f',
                fontSize: '1.5em',
            },
        },
        MuiDivider: {
            root: {
                margin: '20px 0',
            },
        },
        MuiMenu: {
            list: {
                padding: 0,
            },
            paper: {
                backgroundColor: '#939393',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            },
        },
        MuiPaper: {
            root: {
                padding: '10px 0 10px',
                marginBottom: 30,
            },
            rounded: {
                borderRadius: 0,
            },
        },
    },
    typography: {
        fontFamily: [
            '"Open Sans"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        body1: { fontWeight: 300 },
        body2: { fontWeight: 300 },
    },
});

CapMuiTheme = responsiveFontSizes(CapMuiTheme);

export default CapMuiTheme;
