import { LogLevel } from '@azure/msal-browser';
const { AuthRedirectUri, AzureTenantName } = window.runtimeSettings;

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig = {
    auth: {
        clientId: '2820eacd-878d-45e6-b606-42a389577fa2',
        authority: `https://${AzureTenantName}.b2clogin.com/${AzureTenantName}.onmicrosoft.com/B2C_1_cap_signin`,
        knownAuthorities: [`${AzureTenantName}.b2clogin.com`],
        redirectUri: String(AuthRedirectUri),
    },
    cache: {
        cacheLocation: 'localStorage', // 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

const scopeUrl = `https://${AzureTenantName}.onmicrosoft.com/amp-api-alpha`;

export const loginRequest = {
    scopes: ['openid', `${scopeUrl}/write`, `${scopeUrl}/read`],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
