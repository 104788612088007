export enum RouteEnum {
  about = "about",
  ampSkillup = "amp-skillup",
  excite = "excite",
  content = "content-list",
  contentDisplay = "view-content",
  engage = "engage",
  explore = "explore",
  home = "",
  landing = "explore-more",
}
