
import axios from "axios";
const { AmpApiUrl } = window.runtimeSettings;

let config = {
  ajaxTimeout: 25000,
};

export let axiosConfig = {
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
    crossDomain: false,
  },
};

const getHeaders = (headers) => ({
  headers: {
    ...headers,
    ...axiosConfig.headers,
  },
});

export function setConfig(newConfig) {
  config = newConfig;
}

axios.defaults.timeout = config.ajaxTimeout;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export function resolveApiPath(path) {
  if (path.indexOf("http") === -1) {
    return AmpApiUrl + path;
  }
  return path;
}

// For running API locally
// export function resolveApiPath(path) {
//   return path;
// }

export default {
  addRequestHeader(headerKey, headerValue) {
    axiosConfig.headers[headerKey] = headerValue;
  },
  removeRequestHeader(headerKey) {
    delete axiosConfig.headers[headerKey];
  },
  post(path, data, headers = {}, cancelToken = null) {
    return axios.post(resolveApiPath(path), data, getHeaders(headers), cancelToken);
  },
  put(path, data, headers = {}, cancelToken = null) {
    return axios.put(resolveApiPath(path), data, getHeaders(headers), cancelToken);
  },
  get(path, headers = {}, cancelToken = null) {
    return axios.get(resolveApiPath(path), getHeaders(headers), cancelToken);
  },
  del(path, headers = {}, cancelToken = null) {
    return axios.delete(resolveApiPath(path), getHeaders(headers), cancelToken);
  },
  request(instanceOptions, requestOptions) {
    const instance = axios.create(instanceOptions);
    return instance.request(requestOptions);
  },
};
