import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CapMuiTheme from '../../theme/CapMuiTheme';
import { LogoNavBar } from './';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flex: 1,
      paddingTop: theme.spacing(2),
    },
    image: {
      backgroundImage: (props: IProps) => `url(${props.image})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% auto",
      backgroundPosition: "center",
      height: 300,
      marginTop: CapMuiTheme.spacing(4.5),
    },
  })
);

interface IProps {
  image?: string | null | undefined;
}

export const Header = (props: IProps) => {
  const styleProps: IProps = props;
  const classes = useStyles(styleProps);
  return (
    <>
      <div className={classes.wrapper}>
        <LogoNavBar />
        {props.image && <div className={classes.image}></div>}
      </div>
    </>
  );
};
