import React from 'react';
import ReactDOM from 'react-dom';
// import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import CapMuiTheme from './theme/CapMuiTheme';
import { MsalProvider } from '@azure/msal-react';
import { MuiThemeProvider } from '@material-ui/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '../src/services/Auth/authConfig';

// console.log (CapMuiTheme);

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    //https://reactjs.org/docs/strict-mode.html#warning-about-deprecated-finddomnode-usage
    //https://reactjs.org/docs/refs-and-the-dom.html#creating-refs
    <React.StrictMode>
        <MuiThemeProvider theme={CapMuiTheme}>
            {/* <CssBaseline /> */}
            {/* <BrowserRouter> */}
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
            {/* </BrowserRouter> */}
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
