import React from "react";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { CapImages } from "../../assets/CapImages";
import { CapColors } from "../../theme/CapColors";
import CapMuiTheme from "../../theme/CapMuiTheme";
import { LogoNavBar } from "./";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerRoot: {
      backgroundColor: CapColors.background.darkBlue,
      backgroundImage: `url(${CapImages.FooterBackground})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "top",
      textAlign: "center",
      marginTop: theme.spacing(5),
    },
    footerWrapper: {
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyItems: "center",
      [theme.breakpoints.up("xs")]: {
        // height: CapMuiTheme.spacing(11.5),
      },
      [theme.breakpoints.up("md")]: {
        height: CapMuiTheme.spacing(11.5),
      },
      [theme.breakpoints.up("xl")]: {
        height: CapMuiTheme.spacing(11.5),
      },
      margin: 0,
      padding: 0,
      paddingTop: CapMuiTheme.spacing(2.1),
    },
    copyrightFooter: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: CapColors.background.darkBlue,
      height: theme.spacing(4.5),
    },
    copyrightText: {
      fontSize: 11,
      fontWeight: 300,
      color: "#fff",
      marginRight: theme.spacing(2),
      textAlign: "right",
    },
  })
);

const copyright = `© 2020-${new Date().getFullYear()}, Research Foundation for the State University of New York`;

interface IProps {
  home?: boolean | false;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Footer = (props: IProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.footerRoot}>
        <div className={classes.footerWrapper}>
          <LogoNavBar footer home={props.home} />
        </div>
      </div>
      <div className={classes.copyrightFooter}>
        <Typography className={classes.copyrightText}>{copyright}</Typography>
      </div>
    </>
  );
};
