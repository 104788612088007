import React, { useEffect, useState } from 'react';

import { Button, createStyles, makeStyles, Menu, Theme } from '@material-ui/core';

import { ControlStateEnum } from '../../enums/ControlStateEnum';
import { IFocus } from '../../interfaces/IFocus';
import { CapColors } from '../../theme/CapColors';
import ExploreMenuItem from './ExploreMenuItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      display: "flex",
      flex: 1,
      alignItems: "flex-start",
      fontSize: theme.typography.fontSize * 1,
      lineHeight: theme.typography.fontSize * 0.1,
      height: 54,
      "&:hover, &:active, &:focus": {
        backgroundColor: CapColors.background.blue,
        color: CapColors.text.explorerMenu.highlight,
      },
    },
    menuButtonNeutral: {
      backgroundColor: CapColors.background.lightGrey,
      color: CapColors.text.explorerMenu.neutral,
    },
    menuButtonActive: {
      backgroundColor: CapColors.background.blue,
      color: CapColors.text.explorerMenu.highlight,
    },
  })
);

interface IProps {
  activeConcentration: boolean | false;
  activeFocusId: string | undefined;
  displayName: string;
  focuses: IFocus[] | null;
  onClick: (focusId: string | undefined, state: ControlStateEnum) => void;
  slug: string;
}

export const ExploreMenu = (props: IProps) => {
  const classes = useStyles();
  // const navigate = useNavigate();

  const [anchorButton, setAnchorButton] = useState(null);
  const [activeFocusId, setActiveFocusId] = useState(props.activeFocusId);
  const [tempActive, setTempActive] = useState(false);
  const isMenuOpen = Boolean(anchorButton);

  // useEffect(() => {
  //   props.activeFocusId && handleFocusClose(props.activeFocusId);
  // }, [props.activeFocusId]);

  const handleMenuClose = () => {
    setAnchorButton(null);
    setActiveFocusId(undefined);
    setTempActive(false);
    // props.onClick(undefined, ControlStateEnum.Close);
  };

  const handleFocusClose = (focusId: string | undefined) => {
    console.log("handleFocusClose", focusId);
    setAnchorButton(null);
    setActiveFocusId(focusId);
    setTempActive(false);
    props.onClick(focusId, ControlStateEnum.Close);
  };

  const handleMenuOpen = (event: any) => {
    setAnchorButton(event.currentTarget);
    setTempActive(true);
    // props.onClick(undefined, ControlStateEnum.Open);
  };

  const renderButton = () => {
    const stateClass =
      props.activeConcentration || tempActive
        ? classes.menuButtonActive
        : classes.menuButtonNeutral;
    return (
      <Button
        className={`${classes.menuButton} ${stateClass}`}
        onClick={
          props.focuses ? handleMenuOpen : () => handleFocusClose(undefined)
        }
      >
        {props.displayName}
      </Button>
    );
  };

  const renderMenu = () => {
    if (props.focuses) {
      return (
        <Menu
          PaperProps={{ style: { padding: 0 } }}
          anchorEl={anchorButton}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          getContentAnchorEl={null}
          id={`${props.slug}-menu`}
          keepMounted
          onClose={handleMenuClose}
          open={isMenuOpen}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        >
          <ExploreMenuItem
            key={`${props.slug}-menu-item`}
            handleMenuClose={() => handleFocusClose(undefined)}
            menuItemDisplayName={"Overview"}
            menuItemUrl={""}
            active={
              activeFocusId?.toLowerCase() === "overview" &&
              props.activeConcentration
            }
          />
          {props.focuses.map((focus, i) => {
            const menuItemUrl = ""; // btnUrl + '/' + entry.urlSlug;
            return (
              <ExploreMenuItem
                key={`${focus.sys.id}-${i}`}
                handleMenuClose={() => handleFocusClose(focus.sys.id)}
                menuItemDisplayName={focus.displayName}
                menuItemUrl={menuItemUrl}
                active={focus.sys.id === activeFocusId}
              />
            );
          })}
        </Menu>
      );
    }
  };

  return (
    <>
      {renderButton()}
      {renderMenu()}
    </>
  );
};
