import React from 'react';

import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TypographyUtils } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles((theme) => ({
  loadingViewWrapper: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
  },
  loadingContentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingViewWrapper}>
      <div className={classes.loadingContentWrapper}>
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Loading the CAP application...
        </Typography>
        <CircularProgress color="secondary" size={60} />
      </div>
    </div>
  );
};
