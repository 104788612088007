import React from "react";

import { Cta } from "../../components/pageParts";
import { IExplorePanelContent } from "../../interfaces/IExplorePanelContent";
import Helpers from "../../services/Helpers";

interface IProps {
  content: IExplorePanelContent;
  systemSlug: string;
}

export const ExploreCta = (props: IProps) => {
  const focusSlug = props.content.focusSlug
    ? `/${props.content.focusSlug}`
    : "";
  // const url = `/explore-more/${props.systemSlug}/${props.content.concentrationSlug}${focusSlug}`;
  const url = Helpers.contentPageUrl(
    props.systemSlug,
    props.content.concentrationSlug,
    props.content.concentrationId,
    focusSlug
  );
  return (
    <>
      {props.content.concentrationId != null && (
        <Cta
          cta
          exploreKludge
          data={{
            headline: "Want to know more?",
            body: [
              "Find out more about this area including educational resources, training opportunities, potential career paths, and more.",
            ],
            navUrl: url,
          }}
        />
      )}
    </>
  );
};
