import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Loading } from '../../components/Loading';
import { Footer, Header, Positioning } from '../../components/pageParts';
import { ContentContext } from '../../context/ContentContext';
import { ControlStateEnum } from '../../enums/ControlStateEnum';
import { RouteEnum } from '../../enums/RouteEnum';
import { IConcentration } from '../../interfaces/IConcentration';
import { IConcTabManagerItem } from '../../interfaces/IConcTabManagerItem';
import { IExplorePanelContent } from '../../interfaces/IExplorePanelContent';
import { IExplorerImage } from '../../interfaces/IExplorerImage';
import { IExplorerParams } from '../../interfaces/IExplorerParams';
import { IFocus } from '../../interfaces/IFocus';
import { IPositioningInfo } from '../../interfaces/IPositioningInfo';
import { IResource } from '../../interfaces/IResource';
import { ISystem } from '../../interfaces/ISystem';
import Helpers from '../../services/Helpers';
import { CapColors } from '../../theme/CapColors';
import { CapCss } from '../../theme/CapCss';
import CapMuiTheme from '../../theme/CapMuiTheme';
import { ConcentrationRow } from './ConcentrationRow';
import { ContentPanel } from './ContentPanel';
import { ExploreCta } from './ExploreCta';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    explorerWrapper: {
      marginLeft: CapMuiTheme.spacing(2),
      marginRight: CapMuiTheme.spacing(2),
      minHeight: 500,
    },
    buttonWrapper: {
      flexDirection: "row",
      justify: "center",
      alignItems: "center",
      marginBottom: CapMuiTheme.spacing(5),
    },
    systemButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-around",
    },
    systemButton: {
      display: "flex",
      "&:hover, &:active, &:focus": {
        backgroundColor: CapColors.background.lightGrey,
        color: CapColors.text.explorerMenu.neutral,
      },
      color: CapColors.text.explorerMenu.neutral,
      borderStyle: "solid",
      borderWidth: CapMuiTheme.spacing(0.1),
      borderColor: CapColors.background.lightGrey,
    },
    systemButtonNeutral: {
      backgroundColor: CapColors.background.white,
    },
    systemButtonActive: {
      backgroundColor: CapColors.background.lightGrey,
    },
    systemButtonCaption: {
      fontSize: theme.typography.fontSize * 1.5,
      fontWeight: "bold",
    },
    systemImageWrapper: {
      display: "flex",
      // flexDirection: 'column',
      marginTop: CapMuiTheme.spacing(1),
    },
    systemImage: {
      width: "100%",
      maxHeight: "660px",
      objectFit: "cover",
    },
  })
);

interface IStyleProps {
  isOverviewPage: boolean;
  isActive: boolean;
}

export const ExplorePage = () => {
  const params: IExplorerParams = useParams();
  const styleProps: IStyleProps = {
    isOverviewPage: false,
    isActive: false,
  };
  const classes = useStyles(styleProps);
  const content = useContext(ContentContext);
  const navigate = useNavigate();

  const defaultPanelContent: IExplorePanelContent = {
    concentrationId: null,
    concentrationSlug: null,
    focusId: null,
    focusSlug: null,
    state: ControlStateEnum.Close,
  };
  const defaultConcTabManager: IConcTabManagerItem[] = [];
  const defaultSysTabManager: boolean[] = [];
  const defaultSystems: ISystem[] = [];

  const [activeSystem, setActiveSystem] = useState<ISystem>();
  const [concTabManager, setConcTabManager] = useState(defaultConcTabManager);
  const [panelContent, setPanelContent] = useState(defaultPanelContent);
  const [sysTabManager, setSysTabManager] = useState(defaultSysTabManager);
  const [systems, setSystems] = useState<ISystem[]>(defaultSystems);
  const [loading, setLoading] = useState(true);

  const posInfo: IPositioningInfo = {
    headline: "Why Not Change Your World?",
    body: [`${activeSystem ? activeSystem.description ?? "" : ""}`],
  };

  useEffect(() => {
    const initAsync = async () => {
      const allSystems = await content.getSystemsAsync();
      const paramSlug =
        params.systemSlug && allSystems.length > 0
          ? params.systemSlug
          : "facilities-utilities";
      const activeSys = allSystems.find((x) => x.slug == paramSlug);
      const sysOrder = activeSys?.displayOrder ?? -1;
      setSystems(allSystems);
      selectSystem(sysOrder, allSystems);
      activeSys && setActiveSystem(activeSys);
      if (activeSys && params.concentrationSlug && params.focusSlug) {
        const concentration = activeSys.concentrations.find(
          (x) => x.slug == params.concentrationSlug
        );
        const focus = concentration?.focuses?.find(
          (x) => x.slug == params.focusSlug
        );
        concentration &&
          getPanelInfo(
            focus?.sys.id ?? "",
            ControlStateEnum.Immediate,
            concentration
          );
      }
      setLoading(false);
    };
    initAsync();
  }, [params]);

  const selectSystem = (displayOrder: number, capSystems: ISystem[]) => {
    if (capSystems.length > 0) {
      const sysTabs: boolean[] = [];
      capSystems.forEach((system: ISystem) => {
        sysTabs.push(system.displayOrder === displayOrder);
      });
      setSysTabManager([...sysTabs]);
      const concTabs: IConcTabManagerItem[] = [];
      const sys = capSystems.filter(
        (s: ISystem) => s.displayOrder == displayOrder
      )[0];
      sys &&
        sys.concentrations.forEach((conc: IConcentration) => {
          const isActiveConc = conc.slug == params.concentrationSlug;
          const item: IConcTabManagerItem = {
            concentrationId: conc.sys.id,
            active: isActiveConc,
          };
          concTabs.push(item);
        });
      setConcTabManager([...concTabs]);
      setActiveSystem(sys);
      setPanelContent(defaultPanelContent);
    }
  };

  const selectPanel = (panelInfo: IExplorePanelContent) => {
    const close =
      panelInfo.state === ControlStateEnum.Close ||
      panelInfo.state === ControlStateEnum.Immediate;
    if (close) {
      const items: IConcTabManagerItem[] = [];
      concTabManager.forEach((conc) => {
        const item: IConcTabManagerItem = {
          concentrationId: conc.concentrationId,
          active: conc.concentrationId === panelInfo.concentrationId,
        };
        items.push(item);
      });
      setPanelContent(panelInfo);
    }
  };

  const getConcentration = (panelInfo: IExplorePanelContent) => {
    let concentration = activeSystem?.concentrations.find(
      (c: IConcentration) => c.sys.id === panelInfo.concentrationId
    );
    concentration = concentration ?? ({} as IConcentration);
    return concentration;
  };

  const getFocus = (
    panelInfo: IExplorePanelContent,
    concentration: IConcentration
  ): IFocus | null => {
    if (panelInfo.focusId === null || concentration.focuses?.length === 0) {
      return null;
    } else {
      return (
        concentration.focuses?.find((f) => f.sys.id === panelInfo.focusId) ??
        null
      );
    }
  };

  const getPanelInfo = (
    focusId: string,
    state: ControlStateEnum,
    concentration: IConcentration
  ) => {
    const focus = concentration.focuses?.find((f) => f.sys.id === focusId);
    const focusSlug = focus ? focus.slug : null;
    const pinfo: IExplorePanelContent = {
      concentrationId: concentration.sys.id,
      concentrationSlug: concentration.slug,
      focusId,
      focusSlug,
      state,
    };
    selectPanel(pinfo);
  };

  const getPanelImage = (
    concentration: IConcentration,
    focus: IFocus | null
  ) => {
    const explorerThumbnail: IResource | null = focus
      ? focus.explorerThumbnail
      : concentration.explorerThumbnail;
    const image = {
      imgUrl: explorerThumbnail?.mediaUrl,
      linkUrl: explorerThumbnail?.url,
      linkUrlDisplay: explorerThumbnail?.urlDisplay,
    } as IExplorerImage;
    return image;
  };

  const getExplorerImage = (
    concentration: IConcentration,
    focus: IFocus | null
  ) => {
    let explorerImage: IResource | null | undefined = focus
      ? focus.explorerImage
      : concentration.explorerImage;
    explorerImage =
      explorerImage?.mediaUrl == null
        ? activeSystem?.explorerImage
        : explorerImage;
    const image = {
      imgUrl: explorerImage?.mediaUrl,
      linkUrl: explorerImage?.url,
      linkUrlDisplay: explorerImage?.urlDisplay,
    } as IExplorerImage;
    return image;
  };

  const handleNavClick = async (
    focusId: string,
    state: ControlStateEnum,
    concentration: IConcentration,
    activeSystem: ISystem
  ) => {
    const focus: IFocus | undefined = await content.hierarchyItemAsync(
      "focus",
      "id",
      focusId
    );
    const url = Helpers.slugUrl(
      activeSystem.slug,
      concentration.slug,
      focus?.slug ?? "overview"
    );
    navigate(url);
  };

  const renderExplorerSystemRow = () => {
    let buttons = null;
    if (systems.length) {
      buttons = systems.map((system: ISystem, i: number) => {
        const stateClass = sysTabManager[system.displayOrder - 1]
          ? classes.systemButtonActive
          : classes.systemButtonNeutral;
        return (
          <Button
            className={`${classes.systemButton} ${stateClass}`}
            onClick={() => {
              selectSystem(system.displayOrder, systems);
            }}
            component={Link}
            to={`/${RouteEnum.explore}/${system.slug}`}
            key={i}
          >
            <Typography
              variant={"body1"}
              className={classes.systemButtonCaption}
            >
              {system.displayName}
            </Typography>
          </Button>
        );
      });
    }
    return buttons;
  };

  const renderExplorerImage = (panelInfo: IExplorePanelContent) => {
    let imgResource = {} as IExplorerImage;
    if (panelInfo.concentrationId) {
      const concentration: IConcentration = getConcentration(panelInfo);
      const focus: IFocus | null = getFocus(panelInfo, concentration);
      imgResource = getExplorerImage(concentration, focus);
    }
    if ((imgResource.imgUrl ?? "") == "") {
      imgResource.imgUrl = activeSystem?.explorerImage?.mediaUrl ?? "";
    }
    return (
      <>
        <div className={classes.systemImageWrapper}>
          <img className={classes.systemImage} src={imgResource.imgUrl}></img>
        </div>
        <a
          href={imgResource.linkUrl}
          target="_blank"
          rel="noreferrer"
          style={{
            marginBottom: CapMuiTheme.spacing(5),
          }}
        >
          {imgResource.linkUrlDisplay ?? imgResource.linkUrl}
        </a>
      </>
    );
  };

  const tempConc = getConcentration(panelContent);
  const tempFocus = getFocus(panelContent, tempConc);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.pagePartWrapper}>
          <Header image={activeSystem?.primaryImage?.mediaUrl} />
        </div>
        <div className={classes.pagePartWrapper} style={{ marginBottom: 0 }}>
          <Positioning data={posInfo} explorer />
        </div>
        <div className={classes.explorerWrapper}>
          {loading && <Loading />}
          <div className={classes.systemButtonWrapper}>
            {renderExplorerSystemRow()}
          </div>
          {systems.length > 0 && activeSystem && concTabManager.length > 0 && (
            <div style={{ display: "flex" }}>
              <ConcentrationRow
                activeSystem={activeSystem}
                concTabManager={concTabManager}
                explorerParams={params}
                onClick={handleNavClick}
              />
            </div>
          )}
          <div>
            {activeSystem && (
              <ContentPanel
                activeSystem={activeSystem}
                concentration={tempConc}
                focus={tempFocus}
                imgResource={getPanelImage(tempConc, tempFocus)}
                panelInfo={panelContent}
                onClick={handleNavClick}
              />
            )}
          </div>
          <div>{renderExplorerImage(panelContent)}</div>
          {activeSystem && (
            <div className={classes.pagePartWrapper}>
              <ExploreCta
                content={panelContent}
                systemSlug={activeSystem.slug}
              />
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};
