/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { CapButton } from '../atoms/CapButton';
import { CapColors } from '../../theme/CapColors';
import { CapFonts } from '../../theme/CapFonts';
import { RouteEnum } from '../../enums/RouteEnum';
import { ISystemSummary } from '../../interfaces/ISystemSummary';
import CapMuiTheme from '../../theme/CapMuiTheme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topicCalloutRoot: {
            display: 'flex',
            [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
                flexDirection: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? 'row' : 'row-reverse';
                },
            },
            [theme.breakpoints.up('xl')]: {
                flexDirection: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? 'row' : 'row-reverse';
                },
            },
            justifyContent: 'center',
            alignContent: 'center',
            marginBottom: theme.spacing(5),
            // maxWidth: 1280,
        },
        copyWrapper: {
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('xs')]: {
                flex: 1,
            },
            [theme.breakpoints.up('md')]: {
                flex: 0.5,
                marginLeft: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? theme.spacing(2) : 0;
                },
                marginRight: (props: IProps) => {
                    return !rightOriented(props.systemSummary.displayOrder) ? theme.spacing(2) : 0;
                },
                transform: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? `translateX(+30px)` : `translateX(-24px)`;
                },
            },
            [theme.breakpoints.up('xl')]: {
                flex: 0.5,
                marginLeft: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? theme.spacing(2) : 0;
                },
                marginRight: (props: IProps) => {
                    return !rightOriented(props.systemSummary.displayOrder) ? theme.spacing(2) : 0;
                },
                transform: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder) ? `translateX(+30px)` : `translateX(-30px)`;
                },
            },
        },
        copyBox: {
            display: 'flex',
            [theme.breakpoints.up('xs')]: {
                flexGrow: 1,
            },
            [theme.breakpoints.up('md')]: {
                // flexGrow: 0.6,
                flex: 0.8,
            },
            [theme.breakpoints.up('xl')]: {
                // flexGrow: 0.6,
                flex: 0.8,
            },
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: CapColors.background.lightGrey,
            // clipPath: (props: IProps) => {
            //     return !rightOriented(props.systemSummary.displayOrder)
            //         ? 'polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%)'
            //         : 'polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%)';
            // },
        },
        copy: {
            [theme.breakpoints.up('xs')]: {
                maxWidth: '90%',
                paddingTop: CapMuiTheme.spacing(2),
                paddingBottom: CapMuiTheme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '80%',
                padding: 0,
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: '60%',
                padding: 0,
            },
            textAlign: 'center',
        },
        headline: {
            [theme.breakpoints.up('xs')]: {
                fontSize: CapMuiTheme.typography.fontSize * 1.8,
            },
            [theme.breakpoints.up('md')]: {
                fontSize: CapMuiTheme.typography.fontSize * 2,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: CapMuiTheme.typography.fontSize * 2.5,
            },
            fontFamily: CapFonts.Headline.face,
            fontWeight: CapFonts.Headline.weight,
            marginBottom: theme.spacing(2),
        },
        body: {
            [theme.breakpoints.up('xs')]: {
                fontSize: CapMuiTheme.typography.fontSize * 1.4,
            },
            [theme.breakpoints.up('md')]: {
                fontSize: CapMuiTheme.typography.fontSize * 1.2,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: CapMuiTheme.typography.fontSize * 1.4,
            },
            marginBottom: theme.spacing(2),
        },
        imageWrapper: {
            margin: 0,
            padding: 0,
            flex: 0.55,
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                maxWidth: 460,
            },
            [theme.breakpoints.up('xl')]: {
                maxWidth: 720,
            },
        },
        image: {
            backgroundImage: (props: IProps) => `url(${props.systemSummary.primaryImage.mediaUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            [theme.breakpoints.up('xs')]: {
                backgroundSize: '100% auto',
                minHeight: 260,
                width: 'auto',
            },
            [theme.breakpoints.up('md')]: {
                backgroundSize: 'auto 160%',
                minHeight: 260,
                width: 'auto',
                clipPath: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder)
                        ? 'polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%)'
                        : 'polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%)';
                },
            },
            [theme.breakpoints.up('xl')]: {
                backgroundSize: 'auto 120%',
                minHeight: 420,
                width: 'auto',
                clipPath: (props: IProps) => {
                    return rightOriented(props.systemSummary.displayOrder)
                        ? 'polygon(5% 0%, 100% 0%, 100% 100%, 0% 100%)'
                        : 'polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%)';
                },
            },
        },
    }),
);

type IProps = {
    systemSummary: ISystemSummary;
};

const rightOriented = (position: number) => {
    const isRight = position % 2;
    return isRight;
};

export const SystemCallout = (props: IProps) => {
    const systemInfo = props.systemSummary;
    const classes = useStyles(props);
    return (
        <>
            <div className={classes.topicCalloutRoot}>
                {/* <div className={classes.innerRoot}> */}
                <div className={classes.copyWrapper}>
                    <div className={classes.copyBox}>
                        <div className={classes.copy}>
                            <Typography variant={'h5'} className={classes.headline}>
                                {systemInfo.displayName}
                            </Typography>
                            <Typography variant={'body2'} className={classes.body}>
                                {systemInfo.shortDescription}
                            </Typography>
                            <CapButton
                                caption={'Explore'}
                                solid
                                oval
                                nav
                                url={`/${RouteEnum.explore}/${systemInfo.slug}`}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.imageWrapper}>
                    <div className={classes.image}></div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
};
