/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CapColors } from '../../theme/CapColors';
import CapMuiTheme from '../../theme/CapMuiTheme';

const useStyles = makeStyles((theme: Theme) => ({
  capButton: {
    width: (props: IProps) =>
      props.small ? 30 : props.list ? "default" : props.icon ? 140 : 100,
    height: (props: IProps) =>
      props.oval ? 40 : props.small ? 20 : props.list ? 0 : 40,
    padding: "0 0 0 0",
    marginLeft: (props: IProps) => (props.list ? 0 : CapMuiTheme.spacing(2)),
    marginBottom: (props: IProps) =>
      props.list ? CapMuiTheme.spacing(1) : "default",
    borderRadius: (props: IProps) => (props.oval ? 50 : 0),
    "&:hover, &:active, &:focus": {
      color: CapColors.text.bodyNegative,
    },
    backgroundColor: (props: IProps) => backgrounColor(props, theme),
    transform: (props: IProps) =>
      props.oval ? `50% 50% 0%` : props.list ? "default" : "skew(-5deg)",
    minWidth: (props: IProps) => (props.list ? 0 : undefined),
  },
  capButtonCaption: {
    [theme.breakpoints.up("md")]: {
      fontSize: (props: IProps) =>
        CapMuiTheme.typography.fontSize *
        (props.small || props.footer || props.list ? 1 : 1.2),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: (props: IProps) =>
        CapMuiTheme.typography.fontSize *
        (props.small || props.footer || props.list ? 1.2 : 1.4),
    },
    fontWeight: (props: IProps) => (props.list ? "normal" : 400),
    color: (props: IProps) => fontColor(props),
    "&:hover, &:active, &:focus": {
      color: (props: IProps) =>
        props.list ? CapColors.background.green : CapColors.text.bodyNegative,
    },
    transform: (props: IProps) =>
      props.oval ? `50% 50% 0` : props.list ? "default" : "skew(5deg)",
  },
}));

const backgrounColor = (props: IProps, theme: Theme) => {
  // if (props.icon) {
  //     return props.active ? CapColors.background.green : 'rgba(255,255,255,.75)';
  // } else {
  return props.active
    ? CapColors.background.green
    : props.solid
    ? theme.palette.primary.main
    : "inherit";
  // }
};

const fontColor = (props: IProps) => {
  let fontColor = "";
  if (props.active) {
    fontColor = CapColors.text.bodyNegative;
  } else {
    fontColor =
      props.nav && (props.home || props.footer)
        ? CapColors.text.navButton.home
        : (props.nav && !props.solid) || props.list
        ? CapColors.text.navButton.interior
        : props.solid
        ? CapColors.text.navButton.solid
        : CapColors.text.navButton.home;
  }
  return fontColor;
};

interface IProps {
  active?: boolean | false;
  caption?: string;
  click?: (event: any) => {} | null | void;
  cta?: boolean | false;
  footer?: boolean | false;
  home?: boolean | false;
  icon?: JSX.Element | null;
  list?: boolean | false;
  nav?: boolean | false;
  outline?: boolean | false;
  oval?: boolean | false;
  small?: boolean | false;
  solid?: boolean | false;
  uppercase?: boolean | false;
  url?: string;
}

export const CapButton = (props: IProps): JSX.Element => {
  const styleProps: IProps = props;
  const classes = useStyles(styleProps);
  // const history = useHistory();
  // const upper = { textTransform: 'uppercase' }; // props.uppercase ? 'uppercase' : '';

  //TODO: Kludge until sort out textTransform issues with Typescript and/or makeStyles
  const caption = props.caption
    ? props.uppercase
      ? props.caption.toUpperCase()
      : props.caption
    : null;
  const variant = props.outline ? "outlined" : "text";
  const clickHandler = props.click ? props.click : (event: any) => {};

  // const handleClick = () => {
  // handleChange(collectionId, concentrationId);
  // history.push(
  //     `/discipline/${disciplineUrlName}/${stageUrlName}/${slugify(
  //         collectionDisplayName,
  //     )}/${collectionId}/${slugify(concentrationDisplayName)}/${concentrationId}`,
  // );
  // };

  return (
    <Button
      variant={variant}
      className={classes.capButton}
      onClick={(event: any) => clickHandler(event)}
      component={props.nav || props.cta ? RouterLink : "button"}
      to={props.url ? props.url : ""}
      endIcon={props.icon ? props.icon : ""}
    >
      {caption && (
        <Typography className={classes.capButtonCaption}>{caption}</Typography>
      )}
    </Button>
  );
};
