import CapMuiTheme from './CapMuiTheme';
import { CapColors } from './CapColors';

export const CapCss = {
    root: {
        // // display: 'flex',
        // flexGrow: 1,
        // flexDirection: 'column',
        // padding: CapMuiTheme.spacing(2),
        borderStyle: 'solid',
        borderWidth: '12px 0px 0px 12px',
        borderColor: CapColors.background.darkBlue,
    },
    wrapper: {
        borderStyle: 'solid',
        padding: CapMuiTheme.spacing(2),
        marginBottom: CapMuiTheme.spacing(1),
    },
    pagePartWrapper: {
        display: 'flex',
        //https://github.com/cssinjs/jss/issues/1344
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        flex: 1,
        marginBottom: CapMuiTheme.spacing(5),
    },
};
