import { CapImages } from '../../assets/CapImages';
import { IConcentrationContent } from '../../interfaces/IConcentrationContent';
import { IContentLevel } from '../../interfaces/IContentLevel';
import { IFocus } from '../../interfaces/IFocus';
import { ISystem } from '../../interfaces/ISystem';
import { ISystemSummary } from '../../interfaces/ISystemSummary';
import { ISystemTree } from '../../interfaces/ISystemTree';
import xhr from '../XhrService';
import { LocalStorageEnum } from './LocalStorageEnum';

// //TODO: ERROR TRAPPING AND LOGING

type SystemSummariesType = ISystemSummary[];
type SystemType = ISystem;
type SystemsType = ISystem[];
type ConcentrationContentType = IConcentrationContent;
type SystemTreeType = ISystemTree;
type ContentLevelsType = IContentLevel[];
export type HierarchyFindType = "slug" | "id";
export type HierarchyItemType =
  | "system"
  | "concentration"
  | "focus"
  | "content";

export type ContentProviderServiceType = {
  getConcentrationContentAsync: (
    cfid: string
  ) => Promise<ConcentrationContentType>;
  getContentLevelsAsync: () => Promise<ContentLevelsType>;
  getSystemAsync: (slug: string) => Promise<SystemType> | null;
  getSystemImageAsync: (slug: string) => Promise<any>;
  getSystemSummariesAsync: () => Promise<SystemSummariesType>;
  getSystemsAsync: () => Promise<SystemsType>;
  getSystemTreesAsync: () => Promise<SystemTreeType[]>;
  hierarchyItemAsync: (
    itemType: HierarchyItemType,
    searchType: HierarchyFindType,
    searchValue: string
  ) => Promise<IFocus | undefined>;
};

let concentrationContent: IConcentrationContent = {} as ConcentrationContentType;
let contentLevels: IContentLevel[] = [];
let systemTrees: ISystemTree[] = [];

const fetchLocalCache = async <T>(
  cacheName: LocalStorageEnum,
  url: string,
  dataName: string
) => {
  const locVal = sessionStorage.getItem(cacheName);
  if (locVal) {
    return JSON.parse(locVal) as T;
  } else {
    const response = await xhr.get(url);
    const newVal = response.data.data[dataName];
    sessionStorage.setItem(cacheName, JSON.stringify(newVal));
    return newVal as T;
  }
};

const fetchSystemsAsync = async () => {
  return await fetchLocalCache<ISystem[]>(
    LocalStorageEnum.allSystems,
    "/Cap/ExplorerSystems",
    "capSystems"
  );
};

const getFlattenedFocusesAsync = async () => {
  const locVal = sessionStorage.getItem(LocalStorageEnum.allFocuses);
  if (locVal) {
    return JSON.parse(locVal) as IFocus[];
  } else {
    const allSystems = await fetchSystemsAsync();
    const newVal = [] as IFocus[];
    allSystems.forEach((x) =>
      x.concentrations.forEach((y) => y.focuses && newVal.push(...y.focuses))
    );
    sessionStorage.setItem(LocalStorageEnum.allFocuses, JSON.stringify(newVal));
    return newVal;
  }
};

const getHierarchyItemAsync = async (
  itemType: HierarchyItemType,
  searchType: HierarchyFindType,
  searchValue: string
) => {
  switch (itemType) {
    case "focus":
      const focuses = await getFlattenedFocusesAsync();
      let focus: IFocus | undefined;
      switch (searchType) {
        case "id":
          focus = focuses.find((x) => x.sys.id == searchValue);
          break;
        case "slug":
          focus = focuses.find((x) => x.slug == searchValue);
          break;
      }
      return focus;
    default:
      return undefined;
  }
};

const fetchSystemSummaryAsync = async () => {
  return await fetchLocalCache<ISystemSummary[]>(
    LocalStorageEnum.systemSummaries,
    "/Cap/SystemSummary",
    "capSystems"
  );
};

const fetchSystemTreesAsync = async () => {
  if (systemTrees.length === 0) {
    const response = await xhr.get("/Cap/SystemTrees");
    systemTrees = response.data.data.capSystems;
  }
};

const fetchConcentrationContentAsync = async (cfid: string) => {
  const url = `/Cap/ConcentrationContent/${cfid}`;
  const response = await xhr.get(url);
  // console.log('fetchConcentrationContentAsync', cfid, url, response);
  concentrationContent = response.data.data;
};

const fetchContentLevelsAsync = async () => {
  const url = `/Cap/ContentLevels`;
  const response = await xhr.get(url);
  contentLevels = response.data.data;
};

export const ContentService: ContentProviderServiceType = {
  hierarchyItemAsync: async (
    itemType: HierarchyItemType,
    searchType: HierarchyFindType,
    searchValue: string
  ) => {
    return await getHierarchyItemAsync(itemType, searchType, searchValue);
  },
  getConcentrationContentAsync: async (cfid: string) => {
    await fetchConcentrationContentAsync(cfid);
    return concentrationContent;
  },
  getContentLevelsAsync: async () => {
    await fetchContentLevelsAsync();
    return contentLevels;
  },
  getSystemAsync: async (slug: string) => {
    const systems = await fetchSystemsAsync();
    return systems.filter((s) => s.slug === slug)[0];
  },
  getSystemSummariesAsync: async () => {
    return await fetchSystemSummaryAsync();
    // return systemSummaries;
  },
  getSystemImageAsync: async (slug: string) => {
    await fetchSystemsAsync();
    let image;
    switch (slug) {
      case "equipment-support":
        image = CapImages.HomeEquipment;
        break;
      case "facilities-utilities":
        image = CapImages.HomeFacilities;
        break;
      case "process-integration":
        image = CapImages.HomeProcess;
        break;
    }
    return image;
  },
  getSystemsAsync: async () => {
    return await fetchSystemsAsync();
    // return systems;
  },
  getSystemTreesAsync: async () => {
    await fetchSystemTreesAsync();
    return systemTrees;
  },
};
