import React, { useContext } from 'react';
import { useNavigate } from 'react-router';

import { ContentContext } from '../../context/ContentContext';
import { ControlStateEnum } from '../../enums/ControlStateEnum';
import { IConcentration } from '../../interfaces/IConcentration';
import { IConcTabManagerItem } from '../../interfaces/IConcTabManagerItem';
import { IExplorerParams } from '../../interfaces/IExplorerParams';
import { IFocus } from '../../interfaces/IFocus';
import { INavParams } from '../../interfaces/INavParams';
import { ISystem } from '../../interfaces/ISystem';
import Helpers from '../../services/Helpers';
import { ExploreMenu } from './ExploreMenu';

interface IProps {
  activeSystem: ISystem;
  concTabManager: IConcTabManagerItem[];
  explorerParams: IExplorerParams | undefined;
  onClick: (
    focusId: string,
    state: ControlStateEnum,
    concentration: IConcentration,
    activeSystem: ISystem
  ) => Promise<void>;
}

export const ConcentrationRow = (props: IProps) => {
  const menus = props.activeSystem.concentrations.map((x, index) => {
    const isActive = props.concTabManager.filter(
      (c) => c.concentrationId === x.sys.id
    )[0].active;
    let activeFocusId: string | undefined = undefined;
    if (props.explorerParams?.focusSlug) {
      const activeFocus = x.focuses?.find(
        (x) => x.slug == props.explorerParams?.focusSlug
      );
      activeFocusId = activeFocus?.sys.id;
    } else {
      activeFocusId = "overview";
    }
    return (
      <ExploreMenu
        activeConcentration={isActive}
        activeFocusId={activeFocusId}
        displayName={x.displayName}
        focuses={x.focuses}
        key={`${x.sys.id}-${index}`}
        onClick={async (focusId, state) => {
          await props.onClick(focusId ?? "", state, x, props.activeSystem);
        }}
        slug={x.slug}
      />
    );
  });

  return <>{menus}</>;
};
