import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, AppBar } from '@material-ui/core';
import { LogoNavBar } from '.';
import { CapImages } from '../../assets/CapImages';
import CapMuiTheme from '../../theme/CapMuiTheme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        homeHeaderRoot: {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `url(${CapImages.HomeHeader})`,
            // backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            textAlign: 'center',
            borderRadius: '0% 0% 50% 50% / 0% 0% 25% 25%',
            [theme.breakpoints.up('xs')]: {
                minHeight: 300,
            },
            [theme.breakpoints.up('md')]: {
                minHeight: 260,
            },
            [theme.breakpoints.up('xl')]: {
                minHeight: 260,
            },
        },
        contentWrapper: {
            // flexDirection: 'column',
            // margin: 0,
            // padding: 0,
            flex: 1,
        },
        heroWrapper: {
            alignSelf: 'center',
        },
        heroHeadline: {
            [theme.breakpoints.up('xs')]: {
                fontSize: CapMuiTheme.typography.fontSize * 2.2,
            },
            [theme.breakpoints.up('md')]: {
                fontSize: CapMuiTheme.typography.fontSize * 2.8,
            },
            [theme.breakpoints.up('xl')]: {
                fontSize: CapMuiTheme.typography.fontSize * 4.3,
            },
        },
        heroBody: {
            // [theme.breakpoints.up('xs')]: {
            //     fontSize: CapMuiTheme.typography.fontSize * 1.2,
            // },
            // [theme.breakpoints.up('md')]: {
            //     fontSize: CapMuiTheme.typography.fontSize * 1.4,
            // },
            [theme.breakpoints.up('xl')]: {
                fontSize: CapMuiTheme.typography.fontSize * 1.8,
            },
        },
        emphasisText: {
            textTransform: 'uppercase',
            fontStyle: 'italic',
            marginLeft: theme.spacing(1),
        },
        shadowText: {
            textShadow: '2px 2px #363636',
        },
    }),
);

export const HeaderHome = (): JSX.Element => {
    const classes = useStyles();
    return (
        <>
            <AppBar position="relative" className={classes.homeHeaderRoot} elevation={0}>
                <div className={classes.contentWrapper}>
                    <LogoNavBar home />
                </div>
                <div className={classes.contentWrapper}>
                    <div className={classes.heroWrapper}>
                        <Typography
                            variant={'h2'}
                            display="inline"
                            className={`${classes.heroHeadline} ${classes.shadowText} `}
                        >
                            Your Career.
                        </Typography>
                        <Typography
                            variant={'h2'}
                            display="inline"
                            className={`${classes.heroHeadline} ${classes.shadowText} ${classes.emphasisText}`}
                        >
                            Accelerated.
                        </Typography>
                        <Typography variant={'h5'} className={`${classes.heroBody} ${classes.shadowText}`}>
                            Energize your career with virtually limitless options.
                        </Typography>
                    </div>
                </div>
            </AppBar>
        </>
    );
};
