/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Loading } from '../components/Loading';
import { Footer, HeaderHome, Positioning, SystemCallout } from '../components/pageParts';
import { ContentContext } from '../context/ContentContext';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { ISystemSummary } from '../interfaces/ISystemSummary';
import { loginRequest } from '../services/Auth/authConfig';
import Helpers from '../services/Helpers';
import { CapCss } from '../theme/CapCss';

// import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
// import { Button } from '@material-ui/core';
// import { loginRequest } from '../authConfig';
// import { IPublicClientApplication } from '@azure/msal-browser';
// import { FaLock, FaLockOpen, FaUserLock, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
// import { AccountButton } from '../components/atoms/AccountButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    calloutWrapper: {
      // borderStyle: 'solid',
      // // display: 'inline-block',
      // // width: '100%',
      // // maxWidth: 1280,
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
    },
  })
);

const positioningData: IPositioningInfo = {
  headline: "Work With Us. Make Things that Matter.",
  body: [
    "Be a part of solutions enabling how we live today, and technologies driving what’s possible for tomorrow.",
    "Explore nearly limitless career opportunities in our world class facilities and map out the career of your dreams.",
  ],
};

function ProfileContent() {
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState<string>();

  const name = accounts[0] && accounts[0].name;

  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.accessToken);
        // console.log('ProfileContent', response);
        const headers = new Headers();
        const bearer = "Bearer " + response.accessToken;
        headers.append("Authorization", bearer);
        const options = {
          method: "GET",
          headers: headers,
        };
        const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

        fetch(graphEndpoint, options).then((resp) => {
          console.log("ProfileContent", resp);
        });
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setAccessToken(response.accessToken);
        });
      });
  }

  return (
    <>
      <h5 className="card-title">Welcome {name}</h5>
      {accessToken ? (
        <p>
          Access Token Acquired!
          <br />
          {accessToken}
        </p>
      ) : (
        <Button onClick={RequestAccessToken}>Request Access Token</Button>
      )}
    </>
  );
}

export const HomePage = () => {
  const classes = useStyles();
  const content = useContext(ContentContext);

  const defaultSystemSummaries: ISystemSummary[] = [];

  const [systems, setSystems] = useState(defaultSystemSummaries);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAsync = async () => {
      const systemSummaries = await content.getSystemSummariesAsync();
      setSystems(systemSummaries);
      setLoading(false);
    };
    initAsync();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {/* <ProfileContent /> */}
        <HeaderHome />
        <Positioning home data={positioningData} />
        <div className={classes.calloutWrapper}>
          {loading ? (
            <Loading />
          ) : (
            systems.map((topic: ISystemSummary) => {
              return (
                <SystemCallout systemSummary={topic} key={Helpers.uuid()} />
              );
            })
          )}
        </div>
        <Footer home />
      </div>
    </>
  );
};
