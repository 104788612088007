import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CapColors } from '../../theme/CapColors';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: "#fff",
    backgroundColor: CapColors.background.green,
    "&:hover, &:active, &:focus": {
      backgroundColor: CapColors.background.blue,
    },
  },
  menuItemActive: {
    color: "#fff",
    backgroundColor: CapColors.background.blue,
    "&:hover, &:active, &:focus": {
      backgroundColor: CapColors.background.blue,
    },
  },
  menuItemText: {
    fontSize: "0.875rem",
  },
}));

interface IProps {
  handleMenuClose: () => void;
  menuItemDisplayName: string;
  menuItemUrl: string;
  active: boolean;
}

// TODO: Do we need to memoize this for performance? -- not sure
// Uses forwardRef to pass the ref from MUI's Menu component to MenuItem
// eslint-disable-next-line react/display-name
const ExploreMenuItem = React.forwardRef((props: IProps, ref: any) => {
  const classes = useStyles();
  return (
    <MenuItem
      ref={ref}
      // component={RouterLink}
      // to={menuItemUrl}
      onClick={props.handleMenuClose}
      // onMouseOver={props.handleMenuClose}
      classes={{ root: classes.menuItemText }}
      className={props.active ? classes.menuItemActive : classes.menuItem}
    >
      {/* {props.menuItemDisplayName.toUpperCase()} */}
      {props.menuItemDisplayName}
    </MenuItem>
  );
});

export default ExploreMenuItem;
