import React, { createContext } from 'react';

import { IConcentrationContent } from '../interfaces/IConcentrationContent';
import { IContentLevel } from '../interfaces/IContentLevel';
import { ISystem } from '../interfaces/ISystem';
import { ISystemSummary } from '../interfaces/ISystemSummary';
import { ISystemTree } from '../interfaces/ISystemTree';
import { ContentProviderServiceType } from '../services/ContentService/ContentService';

const tempConcentrationContent: IConcentrationContent = {} as IConcentrationContent;
const tempContentLevels: IContentLevel[] = [];
const tempSystem: ISystem = {} as ISystem;
const tempSystemSummaries: ISystemSummary[] = [];
const tempSystems: ISystem[] = [];
const tempSystemTrees: ISystemTree[] = [];

const defaultContext: ContentProviderServiceType = {
  getConcentrationContentAsync: async () => tempConcentrationContent,
  getContentLevelsAsync: async () => tempContentLevels,
  getSystemAsync: async () => tempSystem,
  getSystemImageAsync: async () => null,
  getSystemSummariesAsync: async () => tempSystemSummaries,
  getSystemsAsync: async () => tempSystems,
  getSystemTreesAsync: async () => tempSystemTrees,
};

export const ContentContext = createContext<ContentProviderServiceType>(
  defaultContext
);

export const ContentContextProvider = ContentContext.Provider;

export const ContentContextConsumer = ContentContext.Consumer;

export const withContentContexttHOC = (Component: any) => (props: any) => (
  <ContentContextConsumer>
    {(state) => <Component {...props} contentService={state} />}
  </ContentContextConsumer>
);
