import React, { useEffect, useState } from 'react';
import {
    Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel
} from 'react-accessible-accordion';
import { FaExternalLinkAlt } from 'react-icons/fa';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';

import { RouteEnum } from '../../enums/RouteEnum';
import { IConcentrationContent } from '../../interfaces/IConcentrationContent';
import { IContent } from '../../interfaces/IContent';
import { IContentLevel } from '../../interfaces/IContentLevel';
import { ICourse } from '../../interfaces/ICourse';
import { IFocusContent } from '../../interfaces/IFocusContent';
import { AccountButton } from '../../services/Auth/AccountButton';
import { CapColors } from '../../theme/CapColors';
// import { Link } from 'react-router-dom';
import CapMuiTheme from '../../theme/CapMuiTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {},
    accordionItem: {
      marginBottom: CapMuiTheme.spacing(1),
    },
    accordionHeading: {},
    accordionButton: {
      fontSize: theme.typography.fontSize * 2.4,
      color: CapColors.text.bodyNegative,
      cursor: "pointer",
      height: CapMuiTheme.spacing(5),
      width: "100%",
      textAlign: "left",
      border: "none",
      "&:hover": {},
      "&:before": {
        display: "inline-block",
        content: '""',
        marginRight: CapMuiTheme.spacing(1),
        marginBottom: CapMuiTheme.spacing(0.5),
        marginLeft: CapMuiTheme.spacing(2),
        width: 0,
        height: 0,
        borderTopWidth: 6,
        borderTopStyle: "solid",
        borderTopColor: "transparent",
        borderBottomWidth: 6,
        borderBottomStyle: "solid",
        borderBottomColor: "transparent",
        borderLeftWidth: 12,
        borderLeftStyle: "solid",
        borderLeftColor: CapColors.background.white,
      },
      "&[aria-elected='true']::before, &[aria-expanded='true']::before": {
        transform: "rotate(90deg)",
      },
    },
    accordionPanel: {
      padding: 20,
      // animation: 'fadein 0.35s ease-in',
    },
    active: {
      backgroundColor: CapColors.background.green,
    },
    inactive: {
      backgroundColor: CapColors.background.lightBlue,
    },
    contentColumn: {
      marginBottom: CapMuiTheme.spacing(2),
    },
    courseColumn: {
      marginBottom: CapMuiTheme.spacing(2),
    },
    levelHeader: {
      fontSize: CapMuiTheme.typography.fontSize * 1.8,
      fontWeight: 600,
      marginBottom: CapMuiTheme.spacing(1),
    },
    listHeader: {
      fontSize: CapMuiTheme.typography.fontSize * 1.5,
      fontWeight: 600,
      marginLeft: CapMuiTheme.spacing(2),
    },
    listItem: {
      marginRight: CapMuiTheme.spacing(2),
      fontSize: CapMuiTheme.typography.fontSize * 1.5,
      marginLeft: CapMuiTheme.spacing(2),
    },
  })
);

interface IProps {
  concentration: IConcentrationContent;
  focusSlug: string;
  contentLevels: IContentLevel[];
  systemSlug: string;
}

export const FocusAccordion = (props: IProps): JSX.Element => {
  const classes = useStyles();

  const [activeAccordions, setActiveAccordions] = useState<string[]>([]);

  useEffect(() => {
    // const initAsync = async () => {};
    setActiveAccordions([props.focusSlug]);
    // initAsync();
  }, []);

  const countItems = (focus: IFocusContent) => {
    const contentCount = focus.content?.length ?? 0;
    let subContentCount = 0;
    focus.courses?.forEach(
      (c) => (subContentCount += c.content ? c.content.length : 0)
    );
    const count = contentCount + subContentCount;
    return count;
  };

  const renderLink = (
    item: IContent,
    className: string,
    focus: IFocusContent,
    index: number
  ) => {
    //TODO: Cleanup this method once account wall is implemented
    const contentType = item.mediaSelection?.file.contentType;
    //   const isViewer = contentType ? contentType.match(/video|image/) : false;
    const isViewer =
      contentType == undefined ? false : contentType.match(/video|image/);
    const isScorm = item.contentType
      ? item.contentType[0].displayName == "SCORM"
      : false;
    const isHyperlink = item.resourceType?.some((x) => x == "Hyperlink");
    const isLocked = item.lockedContent != null;
    const isBin95 = item.lockedContent
      ? item.lockedContent[0] == "Bin95"
      : false;
    const isEdwards = item.lockedContent
      ? item.lockedContent[0] == "Edwards"
      : false;
    const isTel = isScorm && !item.lockedContent;
    if (isViewer) {
      const route = `/${RouteEnum.contentDisplay}/${props.systemSlug}/${props.concentration.slug}/${focus.slug}/item-slug/${props.concentration.sys.id}/${focus.sys.id}/${item.sys.id}/`;
      return (
        <>
          <Link href={route} className={className} key={`viewer-link-${index}`}>
            {item.displayName}
          </Link>
        </>
      );
    } else if (isScorm) {
      const library = isEdwards ? "edwards" : isTel ? "tel" : "";
      if (isLocked) {
        return (
          <>
            <AuthenticatedTemplate>
              <Link
                href={`https://cap.mezmatone.net/${library}/${item.externalContentId}/story.html`}
                target={"_blank"}
                className={className}
                key={`viewer-link-${index}`}
              >
                {item.displayName}
              </Link>
              <AccountButton />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <div
                className={className}
                style={{ color: "grey", display: "inline-flex" }}
              >
                {item.displayName}
              </div>
              <AccountButton />
            </UnauthenticatedTemplate>
          </>
        );
      } else {
        return (
          <>
            <Link
              href={`https://cap.mezmatone.net/${library}/${item.externalContentId}/story.html`}
              target={"_blank"}
              className={className}
              key={`viewer-link-${index}`}
            >
              {item.displayName}
            </Link>
          </>
        );
      }
    } else if (isBin95) {
      // const url = item.mediaSelection?.file.url ?? '';
      // //Kludge to get the third id in the URL.  Can't find Contentful documentation explaining where it comes from.
      // const pathArray = url.split('/');
      // const assetId = pathArray[4] ?? '';
      // const unknownId = pathArray[5] ?? '';
      // //Manage this via config or something related to the HTTP service
      // const apiUrl = `${AmpApiUrl}/CAP/Bin95/?assetId=${assetId}&unknownId=${unknownId}&displayName=${item.mediaSelection?.file.fileName}`;
      const apiUrl = item.mediaSelection?.file.url;
      const target = "_self";
      // console.log('renderLink', item.mediaSelection?.file);
      return (
        <>
          <AuthenticatedTemplate>
            <Link
              href={apiUrl}
              target={target}
              className={className}
              key={`viewer-link-${index}`}
            >
              {item.displayName}
            </Link>
            <AccountButton />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div
              className={className}
              style={{ color: "grey", display: "inline-flex" }}
            >
              {item.displayName}
            </div>
            <AccountButton />
          </UnauthenticatedTemplate>
        </>
      );
    } else {
      const url = isHyperlink ? item.url ?? "" : item.mediaSelection?.file.url;
      const target = isHyperlink ? "_blank" : "_self";
      return (
        <>
          <Link
            href={url}
            target={target}
            className={className}
            key={`viewer-link-${index}`}
          >
            {item.displayName}
            {isHyperlink && (
              <span
                style={{
                  marginLeft: 10,
                  fontSize: CapMuiTheme.typography.fontSize * 1,
                }}
              >
                <FaExternalLinkAlt />
              </span>
            )}
          </Link>
        </>
      );
    }
  };

  const renderSubContent = (course: ICourse, focus: IFocusContent) => {
    const reOrdered = ampOrder(course.content);
    const output = reOrdered?.map((item: IContent, index: number) => {
      return renderLink(item, classes.listItem, focus, index);
    });
    return output;
  };

  const renderCourses = (courses: ICourse[], focus: IFocusContent) => {
    const output = courses.map((course: ICourse, index: number) => {
      const content = renderSubContent(course, focus);
      // console.log('renderCourses', content);
      return (
        <div className={classes.courseColumn} key={`course-${index}`}>
          <Typography className={classes.listHeader}>
            {course.displayName}
          </Typography>
          {content}
        </div>
      );
    });
    return output;
  };

  const renderContent = (content: IContent[], focus: IFocusContent) => {
    const reOrdered = ampOrder(content) ?? content;
    const output = reOrdered.map((item: IContent, index: number) => {
      // console.log('renderContent', item.mediaSelection?.file.url);
      return (
        <div className={classes.contentColumn} key={`content-${index}`}>
          {renderLink(item, classes.listHeader, focus, index)}
        </div>
      );
    });
    return output;
  };

  const ampOrder = (content: IContent[] | null) => {
    let newOrder;
    if (content) {
      //TODO: Put in Config file, or manage with a call to the API
      const ampContentCfid = "3kacP73d7f8dO8hiv8gHoy";
      newOrder = content.filter((c) => c.sys.id != ampContentCfid);
      const amp = content.filter((c) => c.sys.id == ampContentCfid)[0];
      if (amp) {
        newOrder.unshift(amp);
      }
    }
    return newOrder;
  };

  const renderPanel = (focus: IFocusContent) => {
    let count = 0;
    if (countItems(focus) == 0) {
      return <>No content available</>;
    } else {
      let matchingContent: IContent[] = [];
      let matchingCourses: ICourse[] = [];
      const output = props.contentLevels.map((level) => {
        matchingContent =
          focus.content?.filter((c) =>
            c.contentLevel
              ? c.contentLevel[0]
                ? c.contentLevel[0].sys.id == level.sys.id
                : false
              : false
          ) ?? [];
        matchingCourses =
          focus.courses?.filter((c) =>
            c.courseLevel
              ? c.courseLevel[0]
                ? c.courseLevel[0].sys.id == level.sys.id
                : false
              : false
          ) ?? [];
        count = matchingContent.length + matchingCourses.length;
        if (count > 0) {
          return (
            <>
              <div className={classes.levelHeader}>{level.displayName}</div>
              {renderContent(matchingContent, focus)}
              {renderCourses(matchingCourses, focus)}
            </>
          );
        }
      });
      return output;
    }
  };

  return (
    <>
      <Accordion
        className={classes.accordion}
        allowMultipleExpanded
        allowZeroExpanded
        preExpanded={[props.focusSlug]}
        onChange={(x) => {
          setActiveAccordions(x);
        }}
      >
        {props.concentration?.focuses?.map((f: IFocusContent) => {
          return (
            <AccordionItem
              className={classes.accordionItem}
              key={f.sys.id}
              uuid={f.slug}
            >
              <AccordionItemHeading className={classes.accordionHeading}>
                <AccordionItemButton
                  className={`${classes.accordionButton} ${
                    activeAccordions.includes(f.slug)
                      ? classes.active
                      : classes.inactive
                  }`}
                >
                  {`${f.displayName} (${countItems(f)})`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={classes.accordionPanel}>
                {renderPanel(f)}
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};
