import React, { useEffect, useState } from 'react';

import { Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Placeholders } from '../../assets/Placeholders';
import { IPositioningInfo } from '../../interfaces/IPositioningInfo';
import Helpers from '../../services/Helpers';
import { CapColors } from '../../theme/CapColors';
import { CapFonts } from '../../theme/CapFonts';
import CapMuiTheme from '../../theme/CapMuiTheme';
import { CapButton } from '../atoms/CapButton';
import { Breadcrumb, IBreadcrumbProps } from './Breadcrumb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    posRoot: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    contentWrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      textAlign: "center",
      [theme.breakpoints.up("xs")]: {
        marginTop: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(4) : CapMuiTheme.spacing(0.66),
        marginBottom: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(3.33) : CapMuiTheme.spacing(0),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(12) : CapMuiTheme.spacing(2),
        marginBottom: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(10) : CapMuiTheme.spacing(0),
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(12) : CapMuiTheme.spacing(2),
        marginBottom: (props: IProps) =>
          props.home ? CapMuiTheme.spacing(10) : CapMuiTheme.spacing(0),
      },
    },
    contentWrapperImage: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(2),
      marginBottom: (props: IProps) =>
        props.landing || props.content ? 0 : theme.spacing(5),
      textAlign: "left",
      backgroundImage: (props: IProps) =>
        props.data ? `url(${props.data.img})` : `url(${Placeholders.Blue})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: (props: IProps) => (props.landing ? 270 : 0),
    },
    elementContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(1),
    },
    element: {
      display: "flex",
      justifyContent: (props: IProps) => (props.landing ? "left" : "center"),
    },
    headline: {
      color: (props: IProps) =>
        props.landing || props.content
          ? CapColors.text.bodyNegative
          : CapColors.text.body,
      fontWeight: CapFonts.Headline.weight,
      [theme.breakpoints.up("xs")]: {
        width: (props: IProps) =>
          props.landing || props.content ? "100%" : "100%",
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.landing ? 2 : 2),
        paddingRight: CapMuiTheme.spacing(2),
        paddingLeft: CapMuiTheme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        width: (props: IProps) =>
          props.landing || props.content ? "66%" : "100%",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.landing ? 4 : 3),
        padding: 0,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.landing ? 4 : 3.5),
        padding: 0,
      },
    },
    bodyText: {
      [theme.breakpoints.up("xs")]: {
        flex: (props: IProps) =>
          props.home
            ? 0.9
            : props.landing
            ? 1
            : props.content
            ? 0.9
            : props.explorer
            ? 0.75
            : 1,
        fontSize: (props: IProps) => CapMuiTheme.typography.fontSize * 1.4,
        paddingRight: CapMuiTheme.spacing(1),
        paddingLeft: CapMuiTheme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        flex: (props: IProps) =>
          props.home
            ? 0.6
            : props.landing
            ? 0.75
            : props.content
            ? 0.9
            : props.explorer
            ? 0.75
            : 1,
        // fontSize: (props: IProps) => CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.5 : 1.8),
        fontSize: (props: IProps) => CapMuiTheme.typography.fontSize * 1.8,
        padding: 0,
      },
      [theme.breakpoints.up("xl")]: {
        flex: (props: IProps) =>
          props.home
            ? 0.6
            : props.landing
            ? 0.75
            : props.content
            ? 0.9
            : props.explorer
            ? 0.75
            : 1,
        // fontSize: (props: IProps) => CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.5 : 1.8),
        fontSize: (props: IProps) => CapMuiTheme.typography.fontSize * 1.8,
        padding: 0,
      },
      color: (props: IProps) =>
        props.landing ? CapColors.text.bodyNegative : CapColors.text.body,
      fontWeight: (props: IProps) =>
        props.landing ? CapFonts.Headline.weight : "normal",
      textAlign: (props: IProps) => (props.content ? "left" : "inherit"),
    },
    buttonWrapper: {
      display: "none",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 0,
    },
    shadowText: { textShadow: "2px 2px 4px rgba(0, 0, 0, 1)" },
    arrow: { transform: "rotate(180deg)" },
  })
);

interface IProps {
  content?: boolean | false;
  data: IPositioningInfo;
  explorer?: boolean | false;
  home?: boolean | false;
  landing?: boolean | false;
}

export const Positioning = (props: IProps) => {
  const styleProps: IProps = props;
  const classes = useStyles(styleProps);
  const data = props.data;
  const theme = useTheme();
  const rootClass =
    props.landing || props.content
      ? classes.contentWrapperImage
      : classes.contentWrapper;

  const [headline, setHeadline] = useState("");
  const [navUrl, setNavUrl] = useState("");

  useEffect(() => {
    const initAsync = async () => {
      if (props.data) {
        setHeadline(props.data.headline);
        setNavUrl(props.data.navUrl ?? "");
      }
    };
    initAsync();
  });

  const renderBody = () => {
    return (
      <div className={classes.elementContainer}>
        {data &&
          data.body.map((par, i) => {
            return (
              <div className={classes.element} key={Helpers.uuid()}>
                <Typography
                  className={`${classes.bodyText} ${
                    props.landing && classes.shadowText
                  }`}
                  style={{
                    marginBottom:
                      i === data.body.length - 1
                        ? 0
                        : props.home || props.content
                        ? theme.spacing(2)
                        : 0,
                  }}
                >
                  {par}
                </Typography>
              </div>
            );
          })}
      </div>
    );
  };

  const renderButton = () => {
    return <CapButton caption={"Explore"} solid cta url={navUrl} />;
  };

  return (
    <>
      <div className={classes.posRoot}>
        <div className={rootClass}>
          <div className={classes.elementContainer}>
            <Typography
              className={`${classes.headline} ${
                (props.landing || props.content) && classes.shadowText
              }`}
            >
              {data && data.headline}
            </Typography>
          </div>
          {!props.content && renderBody()}
          <div className={classes.buttonWrapper}>{renderButton()}</div>
        </div>
        {(props.landing || props.content) && <Breadcrumb />}
        <div
          className={props.content ? classes.contentWrapper : ""}
          style={{
            marginBottom: CapMuiTheme.spacing(props.content ? 1 : 4),
          }}
        >
          {props.content && renderBody()}
        </div>
      </div>
    </>
  );
};
