import React, { useContext, useEffect, useState } from 'react';

import { createStyles, Link, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';

import { CapImages } from '../assets/CapImages';
import { Footer, Header, Positioning } from '../components/pageParts';
import { ContentContext } from '../context/ContentContext';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { ISystem } from '../interfaces/ISystem';
import Helpers from '../services/Helpers';
import { CapColors } from '../theme/CapColors';
import { CapCss } from '../theme/CapCss';
import { CapFonts } from '../theme/CapFonts';
import CapMuiTheme from '../theme/CapMuiTheme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: CapCss.root,
        pagePartWrapper: CapCss.pagePartWrapper,
        element: {
            display: 'flex',
            justifyContent: 'center',
        },
        headline: {
            [theme.breakpoints.up('xs')]: {},
            [theme.breakpoints.up('sm')]: {},
            [theme.breakpoints.up('md')]: {},
            [theme.breakpoints.up('xl')]: {},
            // width: (props: IStyleProps) => (props.landing || props.content ? '100%' : '100%'),
            // fontSize: (props: IStyleProps) => CapMuiTheme.typography.fontSize * (props.landing ? 2 : 2),
            // paddingRight: CapMuiTheme.spacing(2),
            // paddingLeft: CapMuiTheme.spacing(2),
            // color: (props: IStyleProps) =>
            //     props.landing || props.content ? CapColors.text.bodyNegative : CapColors.text.body,
            // fontWeight: CapFonts.Headline.weight,
            textAlign: 'center',
            fontSize: CapMuiTheme.typography.fontSize * 2,
            color: CapColors.text.body,
            fontWeight: CapFonts.Headline.weight,
            marginTop: CapMuiTheme.spacing(2),
            marginBottom: CapMuiTheme.spacing(2),
        },
        bodyText: {
            [theme.breakpoints.up('xs')]: {},
            [theme.breakpoints.up('md')]: {},
            [theme.breakpoints.up('xl')]: {},
            // flex: (props: IStyleProps) =>
            //     props.home ? 0.9 : props.landing ? 1 : props.content ? 0.9 : props.explorer ? 0.75 : 1,
            // fontSize: (props: IStyleProps) => CapMuiTheme.typography.fontSize * 1.4,
            // paddingRight: CapMuiTheme.spacing(1),
            // paddingLeft: CapMuiTheme.spacing(1),
            // color: (props: IStyleProps) => (props.landing ? CapColors.text.bodyNegative : CapColors.text.body),
            // fontWeight: (props: IStyleProps) => (props.landing ? CapFonts.Headline.weight : 'normal'),
            // textAlign: (props: IStyleProps) => (props.content ? 'left' : 'inherit'),
            flex: 0.7,
            marginBottom: CapMuiTheme.spacing(2),
            fontSize: CapMuiTheme.typography.fontSize * 1.25,
            color: CapColors.text.body,
            fontWeight: 'normal',
            textAlign: 'left',
        },
        list: {
            flex: 0.7,
            fontSize: CapMuiTheme.typography.fontSize * 1.25,
            color: CapColors.text.body,
            fontWeight: 'normal',
            marginTop: 0,
            marginRight: CapMuiTheme.spacing(4),
            marginBottom: CapMuiTheme.spacing(2),
            marginLeft: CapMuiTheme.spacing(4),
        },
        listItem: {
            marginBottom: CapMuiTheme.spacing(2),
        },
    }),
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IStyleProps {}

const posInfo: IPositioningInfo = {
    headline: 'AMP / Skillup',
    body: [],
};

// TODO: !!! Consolidate this page with About
export const AmpSkillupPage = (): JSX.Element => {
    const styleProps: IStyleProps = {};
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const content = useContext(ContentContext);

    const [sysImage, setSysImage] = useState();

    useEffect(() => {
        const initAsync = async () => {
            const systemsResponse = await content.getSystemsAsync();
            const sys = systemsResponse.find((s: ISystem) => s.slug == 'facilities-utilities');
            const imgUrl = sys ? sys.primaryImage?.mediaUrl : '';
            setSysImage(imgUrl);
        };
        initAsync();
    }, []);

    return (
        <>
            <div className={classes.root}>
                <div className={classes.pagePartWrapper}>
                    <Header image={sysImage} />
                </div>
                <div className={classes.pagePartWrapper} style={{ margin: 0, marginBottom: CapMuiTheme.spacing(2) }}>
                    {/* <Positioning data={posInfo} /> */}
                    <img src={CapImages.AmpSkillupLogoInverse}></img>
                </div>
                <div className={classes.element} key={Helpers.uuid()}>
                    <Typography className={classes.bodyText}>
                        <Link href={'http://amp.skillupamerica.org'}>http://amp.skillupamerica.org</Link>
                    </Typography>
                </div>
                <div className={classes.element} key={Helpers.uuid()}>
                    <Typography className={classes.bodyText}>
                        CAP provides access to the tools to begin the journey for new and traditionally
                        under-represented communities, through the AMP SkillupAmerica Learning Management System (LMS)
                        by providing students with: integrated assessments, remedial training programs, career pathways
                        exploration, access to on-line content in foundational and intermediate skills in AM and IT,
                        measurable skills gains, industry certifications, and employer engagement with matchmaking with
                        prospective employees.
                    </Typography>
                </div>
                <div className={classes.element} key={Helpers.uuid()}>
                    <Typography className={classes.bodyText}>
                        AMP has worked closely with NY Wired to develop the “AMP SkillupAmerica” (AMP Skillup) LMS to
                        establish a pipeline of students to consider career pathways in advanced manufacturing (AM) and
                        information technology (IT).
                    </Typography>
                </div>
                <div className={classes.element} key={Helpers.uuid()}>
                    <Typography className={classes.bodyText}>
                        Working with NY Wired and in collaboration with regional workforce development entities, AMP
                        Skillup provides an important technology platform to identify and engage prospective employees;
                        and, working with the New York Business Council to engage prospective employers seeking to hire
                        highly skilled employees.
                    </Typography>
                </div>
                <Footer />
            </div>
        </>
    );
};
