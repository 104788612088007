import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { Cta, Footer, Header, Positioning } from '../components/pageParts';
import { ContentContext } from '../context/ContentContext';
import { RouteEnum } from '../enums/RouteEnum';
import { TopicTypeEnum } from '../enums/TopicTypeEnum';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { ITopicBlockInfo } from '../interfaces/ITopicBlockInfo';
import Helpers from '../services/Helpers';
import { CapCss } from '../theme/CapCss';
import CapMuiTheme from '../theme/CapMuiTheme';

const topicBlocks: ITopicBlockInfo[] = [
  {
    type: TopicTypeEnum.explore,
    position: 1,
    headline: TopicTypeEnum.explore,
    body:
      "Begin your career exploration with access to industry-compliant Curated & Developed Curriculum (CDC) content spanning Foundational to Intermediate to Advanced levels.",
  },
  // {
  //   type: TopicTypeEnum.excite,
  //   position: 2,
  //   headline: TopicTypeEnum.excite,
  //   body:
  //     "Pursue your career pathway with access to real world advanced manufacturing facilities and hands-on training simulators while pursuing the educational advancement route that fits your needs.",
  // },
  // {
  //   type: TopicTypeEnum.engage,
  //   position: 3,
  //   headline: TopicTypeEnum.engage,
  //   body:
  //     "Realize your dream of a chosen career advancement route with a focus on systematic career progression using innovative career tools, apprenticeship and on-the-job training opportunities.",
  // },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    ctaWrapper: {
      display: "flex",
      flexDirection: "row",
      justify: "center",
      // alignItems: 'center',
      marginBottom: CapMuiTheme.spacing(10),
      marginLeft: CapMuiTheme.spacing(2),
      marginRight: CapMuiTheme.spacing(2),
    },
    arrow: { transform: "rotate(180deg)" },
  })
);

type ParamsType = {
  systemSlug: string;
  concentrationSlug: string;
  focusSlug?: string;
};

export const LandingPage = () => {
  const params = useParams<ParamsType>();
  const classes = useStyles();
  const theme = useTheme();
  const content = useContext(ContentContext);

  const mqry = {
    xs: useMediaQuery(CapMuiTheme.breakpoints.up("xs")),
    sm: useMediaQuery(CapMuiTheme.breakpoints.up("sm")),
    md: useMediaQuery(CapMuiTheme.breakpoints.up("md")),
    lg: useMediaQuery(CapMuiTheme.breakpoints.up("lg")),
    xl: useMediaQuery(CapMuiTheme.breakpoints.up("xl")),
  };

  const [positioning, setPositioning] = useState<IPositioningInfo>();
  const [slugUrl, setSlugUrl] = useState("");

  useEffect(() => {
    const initAsync = async () => {
      let description = "";
      let displayName = "";
      const sysImg = await content.getSystemImageAsync(params.systemSlug ?? "");
      const sys = await content.getSystemAsync(params.systemSlug ?? "");
      const conc = sys?.concentrations.find(
        (c) => c.slug === params.concentrationSlug
      );
      const focus = conc?.focuses?.find((f) => f.slug === params.focusSlug);
      if (params.focusSlug) {
        description = focus?.description ?? "";
        displayName = focus?.displayName ?? "";
      } else {
        description = conc?.description ?? "";
        displayName = conc?.displayName ?? "";
      }
      // setBody(description);
      const url = Helpers.slugUrl(
        params.systemSlug ?? "",
        params.concentrationSlug ?? "",
        params.focusSlug,
        conc?.sys.id ?? ""
      );
      setSlugUrl(url);
      const posInfo: IPositioningInfo = {
        headline: displayName,
        body: [description],
        img: sysImg,
      };
      setPositioning(posInfo);
    };
    initAsync();
  }, []);

  const renderCta = () => {
    let navUrl = `/${RouteEnum.content}/${slugUrl}`;
    if (mqry.lg) {
      return topicBlocks.map((block: ITopicBlockInfo) => {
        switch (block.type) {
          case TopicTypeEnum.excite:
            navUrl = `/${RouteEnum.excite}`;
            break;
          case TopicTypeEnum.engage:
            navUrl = `/${RouteEnum.engage}`;
            break;
        }
        const ctaInfo: IPositioningInfo = {
          headline: block.headline,
          body: [block.body],
          navUrl,
        };
        return (
          <>
            <Cta cta lpKludge data={ctaInfo} minHeight={1000} />
          </>
        );
      });
    } else {
      const ctaInfo: IPositioningInfo = {
        headline: topicBlocks[0].headline,
        body: [topicBlocks[0].body],
        navUrl,
      };
      return (
        <>
          <Cta cta lpKludge data={ctaInfo} />
        </>
      );
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={classes.pagePartWrapper}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Header />
        </div>
        <div
          className={classes.pagePartWrapper}
          style={{ marginBottom: theme.spacing(0) }}
        >
          {positioning && <Positioning landing data={positioning} />}
        </div>
        <div className={classes.ctaWrapper}>{renderCta()}</div>
        <Footer />
      </div>
    </>
  );
};
