//TODO: This is either A) Temporary until properly get into MUI theme, or B) Permanent and to be improved to make theming more agnostic

const Black = '#000000';
const Blue = '#334ea1';
const DarkBlue = '#184ba0';
const DarkGrey = '#363636';
const Green = '#25b34b';
const LightBlue = '#5085c5';
const LightGrey = '#f6eeed';
const White = '#ffffff';

export const CapColors = {
    background: {
        blue: Blue,
        darkBlue: DarkBlue,
        green: Green,
        lightBlue: LightBlue,
        lightGrey: LightGrey,
        white: White,
    },
    text: {
        body: DarkGrey,
        bodyNegative: White,
        h2: Black,
        explorerMenu: {
            neutral: DarkGrey,
            highlight: White,
        },
        navButton: {
            home: White,
            interior: Blue,
            solid: White,
        },
    },
};
