import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    Checkbox, CheckboxProps, createStyles, FormControlLabel, makeStyles, Theme, useTheme, withStyles
} from '@material-ui/core';

import { Loading } from '../components/Loading';
import { Footer, Header, Positioning } from '../components/pageParts';
import { FocusAccordion } from '../components/pageParts/FocusAccordion';
import { ContentContext } from '../context/ContentContext';
import { ControlStateEnum } from '../enums/ControlStateEnum';
import { IConcentrationContent } from '../interfaces/IConcentrationContent';
import { IContentLevel } from '../interfaces/IContentLevel';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { CapColors } from '../theme/CapColors';
import { CapCss } from '../theme/CapCss';
import CapMuiTheme from '../theme/CapMuiTheme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    filterWrapper: {
      display: "inline-block",
      backgroundColor: CapColors.background.lightGrey,
      marginBottom: CapMuiTheme.spacing(1),
    },
    filterTitle: {
      display: "inline-block",
      fontSize: CapMuiTheme.typography.fontSize * 1.2,
      marginTop: 8,
      marginRight: CapMuiTheme.spacing(1.5),
      marginLeft: CapMuiTheme.spacing(2),
    },
    optionsWrapper: {
      display: "inline-block",
    },
  })
);

type ParamsType = {
  systemSlug: string;
  concentrationSlug: string;
  focusSlug?: string;
  concentrationId: string;
};

interface IAccordionInfo {
  id: string;
  state: ControlStateEnum;
}

interface ICheckboxState {
  id: string;
  checked: boolean | false;
}

export const ContentPage = (): JSX.Element => {
  const params = useParams<ParamsType>();
  const classes = useStyles();
  const theme = useTheme();
  const content = useContext(ContentContext);

  const [concentration, setConcentration] = useState<IConcentrationContent>(
    {} as IConcentrationContent
  );
  const [sysImg, setSysImage] = useState();
  const [title, setTitle] = useState("");
  const [focusDescription, setFocusDescription] = useState("");
  const [concDescription, setConcDescription] = useState("");
  const [contentLevels, setContentLevels] = useState<IContentLevel[]>([]);
  const [activeLevels, setActiveLevels] = useState<IContentLevel[]>([]);
  const [checkboxStatus, setCheckboxStatus] = React.useState<ICheckboxState[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const posInfo: IPositioningInfo = {
    headline: title,
    body: [concDescription, focusDescription],
    img: sysImg,
  };

  useEffect(() => {
    const initAsync = async () => {
      const levels = await content.getContentLevelsAsync();
      setContentLevels(levels);
      setActiveLevels(levels);
      levels.forEach((c) =>
        checkboxStatus.push({ id: c.sys.id, checked: false })
      );
      setCheckboxStatus(checkboxStatus);
      const systemTrees = await content.getSystemTreesAsync();
      const parentSystem = systemTrees.find((sys) =>
        sys.concentrations.find((conc) => conc.sys.id == params.concentrationId)
      );
      const startupConcentration = await content.getConcentrationContentAsync(
        params.concentrationId ?? ""
      );
      setConcentration(startupConcentration);
      const startupFocus = startupConcentration?.focuses?.find(
        (f) => f.slug === params.focusSlug
      );
      const concentrationTitle = startupConcentration?.displayName ?? "";
      const focusTitle = `${concentrationTitle} > ${
        startupFocus?.displayName ?? ""
      }`;
      setConcDescription(startupConcentration?.description ?? "");
      setFocusDescription(startupFocus?.description ?? "");
      if (params.focusSlug) {
        setTitle(focusTitle);
      } else {
        setTitle(concentrationTitle);
      }

      //TODO: 2021-07-21 -- Confirm not needed and delete
      // const contentTabs: IAccordionInfo[] = [];
      // startupConcentration?.focuses?.forEach((f: IFocus) => {
      //     const accordionInfo: IAccordionInfo = {
      //         id: f.sys.id,
      //         state: f.sys.id === startupFocus?.sys.id ? ControlStateEnum.Open : ControlStateEnum.Close,
      //     };
      //     contentTabs.push(accordionInfo);
      // });
      // setContentTabManager([...contentTabs]);

      const img = await content.getSystemImageAsync(params.systemSlug ?? "");
      setSysImage(img);

      setLoading(false);
    };
    initAsync();
  }, []);

  //TODO: Make this a component
  const StyledCheckbox = withStyles({
    root: {
      // color: CapColors.background.green,
      "&$checked": {
        color: CapColors.background.green,
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const handleChange = (event: {
    target: { name: string; checked: boolean };
  }) => {
    const status = checkboxStatus.filter((c) => c.id != event.target.name);
    status.push({ id: event.target.name, checked: event.target.checked });
    setCheckboxStatus(status);
    let active: IContentLevel[] = [];
    status.forEach((s) => {
      if (s.checked) {
        const level = contentLevels.find((l) => l.sys.id == s.id);
        level && active.push(level);
      }
    });
    if (active.length == 0) {
      active = contentLevels;
    }
    active = _.orderBy(active, ["displayOrder"], ["asc"]);
    setActiveLevels(active);
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={classes.pagePartWrapper}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Header />
        </div>
        <div
          className={classes.pagePartWrapper}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Positioning content data={posInfo} />
        </div>
        <div className={classes.filterWrapper}>
          <div className={classes.filterTitle}>Filter Content by</div>
          <div className={classes.optionsWrapper}>
            {contentLevels.map((cat, i: number) => {
              const checked =
                checkboxStatus.find((c: ICheckboxState) => c.id == cat.sys.id)
                  ?.checked ?? false;
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <StyledCheckbox
                      name={cat.sys.id}
                      checked={checked}
                      onChange={handleChange}
                    />
                  }
                  label={cat.displayName}
                />
              );
            })}
          </div>
        </div>
        <div>
          {loading ? (
            <div style={{ marginTop: 20 }}>
              <Loading />
            </div>
          ) : (
            <FocusAccordion
              concentration={concentration}
              contentLevels={activeLevels}
              focusSlug={params.focusSlug ?? ""}
              systemSlug={params.systemSlug ?? ""}
            />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};
