import React, { useEffect, useState } from 'react';

import { Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Placeholders } from '../../assets/Placeholders';
import { IPositioningInfo } from '../../interfaces/IPositioningInfo';
import Helpers from '../../services/Helpers';
import { CapColors } from '../../theme/CapColors';
import { CapFonts } from '../../theme/CapFonts';
import CapMuiTheme from '../../theme/CapMuiTheme';
import { CapButton } from '../atoms/CapButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    positionRoot: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    contentWrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      textAlign: "center",
      [theme.breakpoints.up("xs")]: {
        marginTop: CapMuiTheme.spacing(0.66),
        marginBottom: CapMuiTheme.spacing(0),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: CapMuiTheme.spacing(2),
        marginBottom: CapMuiTheme.spacing(0),
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: CapMuiTheme.spacing(2),
        marginBottom: CapMuiTheme.spacing(0),
      },
    },
    contentWrapperImage: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(2),
      marginBottom: (props: IProps) => (props.content ? 0 : theme.spacing(5)),
      textAlign: "left",
      backgroundImage: (props: IProps) =>
        props.data ? `url(${props.data.img})` : `url(${Placeholders.Blue})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      minHeight: 0,
    },
    elementContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: CapMuiTheme.spacing(1),
    },
    element: {
      display: "flex",
      justifyContent: "center",
      minHeight: (props: IProps) => (props.exploreKludge ? undefined : 225),
    },
    headline: {
      width: (props: IProps) => (props.content ? "66%" : "100%"),
      color: (props: IProps) =>
        props.content ? CapColors.text.bodyNegative : CapColors.text.body,
      fontFamily: CapFonts.Headline.face,
      fontWeight: (props: IProps) =>
        props.lpKludge ? 300 : CapFonts.Headline.weight,
      [theme.breakpoints.up("xs")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 2 : 2),
        paddingRight: CapMuiTheme.spacing(2),
        paddingLeft: CapMuiTheme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 2.5 : 2),
        paddingRight: CapMuiTheme.spacing(2),
        paddingLeft: CapMuiTheme.spacing(2),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 2.5 : 3),
        padding: 0,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 2.5 : 3.5),
        padding: 0,
      },
    },
    bodyText: {
      [theme.breakpoints.up("xs")]: {
        flex: (props: IProps) =>
          props.exploreKludge || props.lpKludge
            ? 0.5
            : props.content
            ? 0.9
            : props.cta
            ? 0.8
            : 1,
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.2 : 1.4),
        paddingRight: CapMuiTheme.spacing(1),
        paddingLeft: CapMuiTheme.spacing(1),
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.4 : 1.4),
        paddingRight: CapMuiTheme.spacing(1),
        paddingLeft: CapMuiTheme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.5 : 1.8),
        padding: 0,
      },
      [theme.breakpoints.up("xl")]: {
        fontSize: (props: IProps) =>
          CapMuiTheme.typography.fontSize * (props.lpKludge ? 1.5 : 1.8),
        padding: 0,
      },
      color: CapColors.text.body,
      textAlign: (props: IProps) => (props.content ? "left" : "inherit"),
    },
    buttonWrapper: {
      display: (props: IProps) => (props.cta ? "flex" : "none"),
      flexDirection: "row",
      justifyContent: "center",
      marginTop: (props: IProps) =>
        props.exploreKludge ? CapMuiTheme.spacing(2) : 0,
    },
    shadowText: { textShadow: "1px 1px 2px rgba(150, 150, 150, 1)" },
  })
);

interface IProps {
  data: IPositioningInfo;
  content?: boolean | false;
  cta?: boolean | false;
  exploreKludge?: boolean | false;
  lpKludge?: boolean | false;
  minHeight?: number;
}

export const Cta = (props: IProps) => {
  const styleProps: IProps = props;
  const classes = useStyles(styleProps);
  const data = props.data;
  const theme = useTheme();
  const rootClass = props.content
    ? classes.contentWrapperImage
    : classes.contentWrapper;

  const [headline, setHeadline] = useState("");
  const [navUrl, setNavUrl] = useState("");

  useEffect(() => {
    const initAsync = async () => {
      if (props.data) {
        setHeadline(props.data.headline);
        setNavUrl(props.data.navUrl ?? "");
      }
    };
    initAsync();
  });

  const renderBody = () => {
    return (
      <div className={classes.elementContainer}>
        {data &&
          data.body.map((par, i) => {
            return (
              <div className={classes.element} key={Helpers.uuid()}>
                <Typography
                  className={`${classes.bodyText}`}
                  style={{
                    marginBottom:
                      i === data.body.length - 1
                        ? 0
                        : props.content
                        ? theme.spacing(2)
                        : 0,
                  }}
                >
                  {par}
                </Typography>
              </div>
            );
          })}
      </div>
    );
  };

  const renderButton = () => {
    return props.cta && !props.exploreKludge ? (
      <CapButton caption={"Begin..."} solid nav cta url={navUrl} />
    ) : (
      <CapButton caption={"Explore"} solid cta url={navUrl} />
    );
  };

  return (
    <>
      <div className={classes.positionRoot}>
        <div className={rootClass}>
          <div className={classes.elementContainer}>
            <Typography
              className={`${classes.headline} ${
                props.content && classes.shadowText
              }`}
            >
              {data && data.headline}
            </Typography>
          </div>
          {!props.content && renderBody()}
          <div className={classes.buttonWrapper}>{renderButton()}</div>
        </div>
        <div
          className={props.content ? classes.contentWrapper : ""}
          style={{ marginBottom: CapMuiTheme.spacing(props.content ? 1 : 4) }}
        >
          {props.content && renderBody()}
        </div>
      </div>
    </>
  );
};
