import React from "react";

import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { CapButton } from "../../components/atoms/CapButton";
import { ControlStateEnum } from "../../enums/ControlStateEnum";
import { RouteEnum } from "../../enums/RouteEnum";
import { IConcentration } from "../../interfaces/IConcentration";
import { IExplorePanelContent } from "../../interfaces/IExplorePanelContent";
import { IFocus } from "../../interfaces/IFocus";
import { ISystem } from "../../interfaces/ISystem";
import Helpers from "../../services/Helpers";
import { CapColors } from "../../theme/CapColors";
import { CapCss } from "../../theme/CapCss";
import { CapFonts } from "../../theme/CapFonts";
import CapMuiTheme from "../../theme/CapMuiTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelRoot: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: CapColors.background.lightGrey,
      marginTop: CapMuiTheme.spacing(1),
      paddingLeft: CapMuiTheme.spacing(3),
    },
    panelCopyColumn: {
      display: "flex",
      flexDirection: "column",
      // flex: 0.7,
      marginTop: CapMuiTheme.spacing(5),
      marginRight: CapMuiTheme.spacing(4),
      width: "55%",
    },
    panelImageColumn: {
      display: "flex",
      flexDirection: "column",
      // flex: 0.3,
      paddingTop: CapMuiTheme.spacing(2),
      paddingRight: CapMuiTheme.spacing(2),
      width: "35%",
      maxWidth: 480,
      // maxHeight: 300,
      marginBottom: CapMuiTheme.spacing(2),
    },
    panelImageColumnNull: {
      display: "flex",
      flexDirection: "column",
      flex: 0,
    },
    panelImage: {
      display: "flex",
      height: "auto",
      width: "100%",
    },
    panelHeadlineWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    panelHeadline: {
      fontFamily: CapFonts.Headline.face,
      fontSize: CapMuiTheme.typography.fontSize * 1.5,
      fontWeight: 400,
      marginRight: CapMuiTheme.spacing(2),
    },
    panelSubhead: {
      fontFamily: CapFonts.Headline.face,
      fontSize: theme.typography.fontSize,
      fontWeight: 400,
      marginBottom: CapMuiTheme.spacing(1),
    },
    panelParagraph: {
      fontSize: theme.typography.fontSize * 1,
      marginTop: CapMuiTheme.spacing(1),
      marginBottom: CapMuiTheme.spacing(2),
    },
    panelListWrapper: {
      marginBottom: CapMuiTheme.spacing(1),
    },
    panelList: {
      fontSize: theme.typography.fontSize,
      textAlign: "left",
    },
  })
);

interface IStyleProps {
  isOverviewPage: boolean;
  isActive: boolean;
}

interface IProps {
  activeSystem: ISystem;
  concentration: IConcentration;
  focus: IFocus | null;
  imgResource: any;
  panelInfo: IExplorePanelContent;
  onClick: (
    focusId: string,
    state: ControlStateEnum,
    concentration: IConcentration,
    activeSystem: ISystem
  ) => Promise<void>;
}

export const ContentPanel = (props: IProps) => {
  const styleProps: IStyleProps = {
    isOverviewPage: false,
    isActive: false,
  };
  const classes = useStyles(styleProps);

  if (props.panelInfo.concentrationId) {
    const displayName = props.focus
      ? props.focus.displayName
      : props.concentration.displayName;
    const description = props.focus
      ? props.focus.description
      : props.concentration.description;
    const focusSlug = props.panelInfo.focusSlug
      ? props.panelInfo.focusSlug
      : "";
    const url = Helpers.contentPageUrl(
      props.activeSystem?.slug,
      props.panelInfo.concentrationSlug,
      props.panelInfo.concentrationId,
      focusSlug
    );
    const focusArray: string[] = [];
    const moreInfoArray: string[] = [];
    props.concentration.focuses
      ?.filter((f: IFocus) => f.sys.id != props.panelInfo.focusId)
      .forEach((focus: IFocus) => {
        focusArray.push(focus.displayName);
        moreInfoArray.push(focus.sys.id);
      });
    moreInfoArray.push(props.panelInfo.focusId ?? "");
    const filteredFocusList = props.concentration.focuses?.filter(
      (focus) => focus.sys.id != props.panelInfo.focusId
    );
    const focusList = filteredFocusList?.map((focus: IFocus, i: number) => {
      return (
        <div key={i}>
          <CapButton
            key={i}
            caption={focus.displayName}
            list
            click={async () =>
              await props.onClick(
                focus?.sys.id ?? "",
                ControlStateEnum.Immediate,
                props.concentration,
                props.activeSystem
              )
            }
          />
        </div>
      );
    });

    return (
      <div className={classes.panelRoot}>
        <div className={classes.panelCopyColumn}>
          <div className={classes.panelHeadlineWrapper}>
            <Typography className={classes.panelHeadline}>
              {displayName}
            </Typography>
            <CapButton caption={"Explore"} url={url} outline nav small />
          </div>
          <Typography className={classes.panelParagraph}>
            {description}
          </Typography>
          <Typography className={classes.panelSubhead}>
            {`Explore more in ${props.concentration.displayName}:`}
          </Typography>
          <div className={classes.panelListWrapper}>
            <div className={classes.panelList} key={Helpers.uuid()}>
              {focusList}
            </div>
          </div>
          {/* NOTE: Keep for future use */}
          {/* <Typography className={classes.panelSubhead}>Related Internal Content</Typography>
                        <div className={classes.panelListWrapper}>
                            <Typography className={classes.panelList} key={Helpers.uuid()}>
                                {moreInfoList} 
                            </Typography>
                        </div> */}
          {/* <Typography className={classes.panelSubhead}>Additional Offsite Resources</Typography>
                        <div className={classes.panelListWrapper}>
                            <Typography className={classes.panelList} key={Helpers.uuid()}>
                                {moreInfoList}
                            </Typography>
                        </div> */}
        </div>
        {/* Panel (Thumbnail) Image */}
        <div
          className={
            props.imgResource.imgUrl == ""
              ? classes.panelImageColumnNull
              : classes.panelImageColumn
          }
        >
          <img className={classes.panelImage} src={props.imgResource.imgUrl} />
          <a href={props.imgResource.linkUrl} target="_blank" rel="noreferrer">
            {props.imgResource.linkUrlDisplay ?? props.imgResource.linkUrl}
          </a>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
