import Green from './placeholders/amp-placeholder-green.png';
import Blue from './placeholders/amp-placeholder-blue.png';
import Pink from './placeholders/amp-placeholder-pink.png';
import Brown from './placeholders/amp-placeholder-brown.png';
import ExploreSystemPlaceholder from './placeholders/system-placeholder.png';
import ExploreConcentrationPlacholder from './placeholders/concentration-placeholder.png';
import ExploreFocusPlaceholder from './placeholders/focus-placeholder.png';
// import { green } from '@material-ui/core/colors';

const PlaceholderUrls = {
    Green: '../../src/assets/placeholders/amp-placeholder-green.png',
    Blue: '../../src/assets/placeholders/amp-placeholder-blue.png',
    Pink: '../../src/assets/placeholders/amp-placeholder-pink.png',
    Brown: '../../src/assets/placeholders/amp-placeholder-brown.png',
};

const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

let lastRandom = 99;

const randomPlaceholder = (): string => {
    let int = getRandomInt(0, 3);
    // console.log(int, lastRandom);
    while (int == lastRandom) {
        //TODO: real recursive logic
        int = getRandomInt(0, 3);
    }
    lastRandom = int;
    switch (int) {
        case 0:
            return Green;
        case 1:
            return Blue;
        case 2:
            return Pink;
        case 3:
            return Brown;
        default:
            return Green;
    }
};

const systemPlaceholder = (index: number): string => {
    // console.log(index);
    switch (index) {
        case 0:
            return ExploreSystemPlaceholder;
        case 1:
            return ExploreSystemPlaceholder;
        case 2:
            return ExploreSystemPlaceholder;
        case 3:
            return ExploreSystemPlaceholder;
        default:
            return ExploreSystemPlaceholder;
    }
};

export const Placeholders = {
    Green,
    Blue,
    Pink,
    Brown,
    ExploreConcentrationPlacholder,
    ExploreFocusPlaceholder,
    ExploreSystemPlaceholder,
    PlaceholderUrls,
    randomPlaceholder,
    systemPlaceholder,
};
