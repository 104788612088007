import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ContentContext } from './context/ContentContext';
import { PathName } from './enums/PathEnum';
import { RouteEnum } from './enums/RouteEnum';
import { TopicTypeEnum } from './enums/TopicTypeEnum';
import { ContentDisplayPage, ContentPage, ExplorePage, HomePage, LandingPage } from './pages';
import { AboutPage } from './pages/AboutPage';
import { AmpSkillupPage } from './pages/AmpSkillupPage';
import { TopicPage } from './pages/TopicPage';
import { ContentService } from './services/ContentService/ContentService';

const useStyles = makeStyles(() =>
  createStyles({
    grow: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
    },
  })
);

function App() {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <ContentContext.Provider value={ContentService}>
        <Router>
          <div className={classes.grow}>
            <Routes>
              <Route index element={<HomePage />} />

              <Route path={`/${RouteEnum.explore}`} element={<ExplorePage />}>
                <Route index />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}/:${PathName.focusId}`}
                />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}`}
                />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}`}
                />
                <Route path={`:${PathName.systemSlug}`} />
              </Route>

              <Route path={`/${RouteEnum.landing}`} element={<LandingPage />}>
                <Route index />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}`}
                />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}`}
                />
                <Route path={`:${PathName.systemSlug}`} />
              </Route>

              <Route path={`/${RouteEnum.content}`} element={<ContentPage />}>
                <Route index />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}/:${PathName.concentrationId}`}
                />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.concentrationId}`}
                />
              </Route>

              <Route
                path={`/${RouteEnum.contentDisplay}`}
                element={<ContentDisplayPage />}
              >
                <Route index />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}/:${PathName.courseSlug}/:${PathName.contentSlug}/:${PathName.concentrationId}/:${PathName.focusId}/:${PathName.courseId}/:${PathName.contentId}`}
                />
                <Route
                  path={`:${PathName.systemSlug}/:${PathName.concentrationSlug}/:${PathName.focusSlug}/:${PathName.contentSlug}/:${PathName.concentrationId}/:${PathName.focusId}/:${PathName.contentId}`}
                />
              </Route>

              <Route
                path={`/${RouteEnum.ampSkillup}`}
                element={<AmpSkillupPage />}
              ></Route>

              <Route
                path={`/${RouteEnum.about}`}
                element={<AboutPage />}
              ></Route>

              <Route
                path={`/${RouteEnum.excite}`}
                element={<TopicPage pageType={TopicTypeEnum.excite} />}
              ></Route>

              <Route
                path={`/${RouteEnum.engage}`}
                element={<TopicPage pageType={TopicTypeEnum.engage} />}
              ></Route>
            </Routes>
          </div>
        </Router>
      </ContentContext.Provider>
    </>
  );
}

export default App;
