import React, { useContext, useEffect, useState } from "react";

import {
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

import { Footer, Header, Positioning } from "../components/pageParts";
import { ContentContext } from "../context/ContentContext";
import { IPositioningInfo } from "../interfaces/IPositioningInfo";
import { ISystem } from "../interfaces/ISystem";
import Helpers from "../services/Helpers";
import { CapColors } from "../theme/CapColors";
import { CapCss } from "../theme/CapCss";
import { CapFonts } from "../theme/CapFonts";
import CapMuiTheme from "../theme/CapMuiTheme";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    element: {
      display: "flex",
      justifyContent: "center",
    },
    headline: {
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
      textAlign: "center",
      fontSize: CapMuiTheme.typography.fontSize * 2,
      color: CapColors.text.body,
      fontWeight: CapFonts.Headline.weight,
      marginTop: CapMuiTheme.spacing(2),
      marginBottom: CapMuiTheme.spacing(2),
    },
    bodyText: {
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
      flex: 0.7,
      marginBottom: CapMuiTheme.spacing(2),
      fontSize: CapMuiTheme.typography.fontSize * 1.25,
      color: CapColors.text.body,
      fontWeight: "normal",
      textAlign: "left",
    },
    list: {
      flex: 0.7,
      fontSize: CapMuiTheme.typography.fontSize * 1.25,
      color: CapColors.text.body,
      fontWeight: "normal",
      marginTop: 0,
      marginRight: CapMuiTheme.spacing(4),
      marginBottom: CapMuiTheme.spacing(2),
      marginLeft: CapMuiTheme.spacing(4),
    },
    listItem: {
      marginBottom: CapMuiTheme.spacing(2),
    },
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IStyleProps {}

const posInfo: IPositioningInfo = {
  headline: "Career Alignment Platform (CAP)",
  body: [],
};

// TODO: !!! Consolidate this page with AmpSkillupPage
export const AboutPage = (): JSX.Element => {
  const styleProps: IStyleProps = {};
  const classes = useStyles(styleProps);
  const theme = useTheme();
  const content = useContext(ContentContext);

  const [sysImage, setSysImage] = useState();

  useEffect(() => {
    const initAsync = async () => {
      const systemsResponse = await content.getSystemsAsync();
      const sys = systemsResponse.find(
        (s: ISystem) => s.slug == "facilities-utilities"
      );
      const imgUrl = sys ? sys.primaryImage?.mediaUrl : "";
      setSysImage(imgUrl);
      console.log("AboutPage", imgUrl);
    };
    initAsync();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.pagePartWrapper}>
          <Header image={sysImage} />
        </div>
        <div className={classes.pagePartWrapper} style={{ margin: 0 }}>
          <Positioning data={posInfo} />
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            The manufacture of semiconductor devices requires highly skilled
            technical workers trained for “digital manufacturing” &mdash;
            High-function technicians and engineers trained in both the advanced
            manufacturing (AM) “hardware systems” incorporating robotic and
            mechatronic components, and information technology (IT) “software
            systems” that integrate and control a “network” of hardware systems
            across an AM enterprise.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            The Career Alignment Platform (CAP) was created by the Advanced
            Manufacturing Performance (AMP) Center to drive a systematic
            progression in skills mastery for technicians and engineers by
            organizing industry-relevant training content by career pathways in
            the AM of computer chips.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Our strategy is to jointly develop systems-focused training content
            and related test-beds organized by career pathways in partnership
            with supply chain companies serving the semiconductor industry. Our
            approach is to support the entire pipeline of workforce development
            by focusing on:
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              Engagement of new and traditionally under-represented communities
              to explore the broad array of AM career opportunities;
            </li>
            <li className={classes.listItem}>
              Attraction of workers currently employed in adjacent sectors who
              may seek to apply their foundational skills to the dynamic
              challenges of AM systems; and
            </li>
            <li>
              Advancement of experienced AM workers through the cross-training
              necessary for performance management within the AM enterprise.
            </li>
          </ul>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            Targeting Traditionally Under-Represented Communities
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            CAP provides access to the tools to begin the journey for new and
            traditionally under-represented communities, through the AMP
            SkillupAmerica Learning Management System (LMS) by providing
            students with: integrated assessments, remedial training programs,
            career pathways exploration, access to on-line content in
            foundational and intermediate skills in AM and IT, measurable skills
            gains, industry certifications, and employer engagement with
            matchmaking with prospective employees.<br></br>
            <br></br>
            <Link href={"http://amp.skillupamerica.org"}>
              http://amp.skillupamerica.org
            </Link>
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            Industry-Relevant Training Content &amp; Test-beds
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Using a system-focused approach, CAP supports targeted industry
            engagement by the AMP Center to jointly curate and develop
            industry-compliant career content and training test-beds targeting
            three AM career pathways that include:
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <ul className={classes.list}>
            <li className={classes.listItem}>Facilities & Utilities</li>
            <li className={classes.listItem}>Equipment & Support</li>
            <li>Process & Integration</li>
          </ul>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Curated and developed career content includes on-line, in-lab and
            in-fab curriculum targeting foundational, intermediate and advanced
            skill levels.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            CAP supports the alignment of in-lab and in-fab hands-on learning
            opportunities with access to E-Learning short courses, workshops,
            instructional animations, axonometric/orthographic illustrations,
            virtual labs, augmented and virtual reality experiences.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Training test-beds complete the educational journey with hands-on
            experiential learning at the bench-level, progressing to the
            tool-level, and concluding with fab-level tailored for the
            respective equipment, facility and process exposures.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            Partner Aligned Training Hub (PATH) Model
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            In a partnership between University at Albany’s College of
            Nanotechnology, Science, and Engineering’s North East Advanced
            Technological Education Center (NEATEC) and the AMP Center, the
            Partner Aligned Training Hub (PATH) model combines AMP’s focus on
            development of training program “capabilities” with NEATEC’s focus
            on training delivery “services” to provide a comprehensive approach
            to workforce training in AM – especially in the emerging data-driven
            “digital manufacturing” sectors. The PATH model can be scaled and
            tailored for regional workforce training needs.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            AMP SkillupAmerica
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            AMP has worked closely with NY Wired to develop the “AMP
            SkillupAmerica” (AMP Skillup) LMS to establish a pipeline of
            students to consider career pathways in advanced manufacturing (AM)
            and information technology (IT).
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Working with NY Wired and in collaboration with regional workforce
            development entities, AMP Skillup provides an important technology
            platform to identify and engage prospective employees; and, working
            with the New York Business Council to engage prospective employers
            seeking to hire highly skilled employees.
            <br></br>
            <br></br>
            <Link href={"http://amp.skillupamerica.org"}>
              http://amp.skillupamerica.org
            </Link>
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            Advanced Manufacturing Performance (AMP) Center
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            The Advanced Manufacturing Performance (AMP) Center at University at
            Albany’s College of Nanotechnology, Science, and Engineering enables
            partner collaboration within a unique open-innovation technology
            model located at the Albany NanoTech complex.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            With a 300mm wafer industry-compliant silicon wafer process line
            operated by NY CREATES in partnership with leading device
            fabrication companies, original equipment manufacturers, and
            ancillary equipment suppliers, the AMP Center is engaging with
            industry to develop a comprehensive solution that includes creation
            of content delivery applications, curated content production,
            training & technology test-beds, and workforce delivery services
            that can be scaled and tailored for regional needs. <br></br>
            <br></br>
            <Link href={"https://www.amperformance.org"}>
              https://www.amperformance.org
            </Link>
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.headline}>
            North East Advanced Technological Education Center (NEATEC)
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            The Northeast Advanced Technological Education Center (NEATEC) is an
            ATE Regional Center funded by the National Science Foundation that
            fulfills the needs of the nanotechnology and semiconductor
            manufacturing industries in New York State and Western New England
            by supporting the education and training of technicians.
          </Typography>
        </div>
        <div className={classes.element} key={Helpers.uuid()}>
          <Typography className={classes.bodyText}>
            Led by University at Albany’s College of Nanotechnology, Science,
            and Engineering, the Center represents a collaborative partnership
            that includes manufacturers, community colleges, regional high
            schools, Fort Drum military installation, National Institute of
            Standards and Technology (NIST) and refugee centers.
            <br></br>
            <br></br>
            <Link href={"https://neatec.org"}>https://neatec.org</Link>
          </Typography>
        </div>
        <Footer />
      </div>
    </>
  );
};
