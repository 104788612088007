import { v4 as uuidv4 } from "uuid";

import { RouteEnum } from "../enums/RouteEnum";

const slugTarget = (
  system: string | undefined,
  concentration: string | undefined,
  focus: string | undefined
) => {
  const target = focus ?? concentration ?? system ?? "";
  return target;
};

const slugTrail = (
  system: string | undefined,
  concentration: string | undefined,
  focus: string | undefined
) => {
  const slugs = [];
  system && slugs.push(system);
  concentration && slugs.push(concentration);
  focus && slugs.push(focus);
  // slugs.pop();
  const trail = slugs.join(" > ");
  return trail;
};

const slugUrl = (
  system: string,
  concentration: string,
  focus: string | undefined,
  concentrationId?: string
) => {
  const slugs = [];
  slugs.push(system);
  slugs.push(concentration);
  focus && slugs.push(focus);
  slugs.push(concentrationId);
  const url = slugs.join("/");
  return url;
};

const uuid = () => {
  return uuidv4();
};

export const contentPageUrl = (
  systemSlug: string | null,
  concentrationSlug: string | null,
  concentrationId: string | null,
  focusSlug: string | null
) => {
  let url = `/${RouteEnum.content}/${systemSlug}/${concentrationSlug}/${focusSlug}/${concentrationId}`;
  url = url.replaceAll("//", "/");
  return url;
};

export type HelpersType = {
  contentPageUrl: (
    systemSlug: string | null,
    concentrationSlug: string | null,
    concentrationId: string | null,
    focusSlug: string | null
  ) => string;
  slugTarget: (
    system: string | undefined,
    concentration: string | undefined,
    focus: string | undefined
  ) => string;
  slugTrail: (
    system: string | undefined,
    concentration: string | undefined,
    focus: string | undefined
  ) => string;
  slugUrl: (
    system: string,
    concentration: string,
    focus: string | undefined,
    concentrationId?: string
  ) => string;
  uuid: () => string;
};

const Helpers: HelpersType = {
  contentPageUrl(systemSlug, concentrationSlug, concentrationId, focusSlug) {
    return contentPageUrl(
      systemSlug,
      concentrationSlug,
      concentrationId,
      focusSlug
    );
  },
  slugTarget: (
    system: string | undefined,
    concentration: string | undefined,
    focus: string | undefined
  ) => {
    return slugTarget(system, concentration, focus);
  },
  slugTrail: (
    system: string | undefined,
    concentration: string | undefined,
    focus: string | undefined
  ) => {
    return slugTrail(system, concentration, focus);
  },
  slugUrl: (
    system: string,
    concentration: string,
    focus: string | undefined,
    concentrationId?: string
  ) => {
    return slugUrl(system, concentration, focus, concentrationId);
  },
  uuid: () => {
    return uuid();
  },
};

export default Helpers;
