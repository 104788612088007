import React from 'react';
import { FaLockOpen, FaUserLock } from 'react-icons/fa'; // FaSignInAlt, FaSignOutAlt;

import { IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CapColors } from '../../theme/CapColors';
import CapMuiTheme from '../../theme/CapMuiTheme';
import { loginRequest } from '../Auth/authConfig';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountButton: {
      fontSize: CapMuiTheme.typography.fontSize * 1.25,
      color: CapColors.background.blue,
    },
  })
);

function handlePolicyChange(response: any, instance: IPublicClientApplication) {
  /**
   * We need to reject id tokens that were not issued with the default sign-in policy.
   * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
   * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
   */

  if (response.idTokenClaims["acr"] === b2cPolicies.names.editProfile) {
    window.alert(
      "Profile has been updated successfully. \nPlease sign-in again."
    );
    instance.logout();
  } else if (
    response.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword
  ) {
    window.alert(
      "Password has been reset successfully. \nPlease sign-in with your new password."
    );
    myMSALObj.logout();
  }
}

function handleLogin(instance: IPublicClientApplication) {
  instance
    // .loginPopup(loginRequest)
    .loginRedirect(loginRequest)
    .then()
    .catch((e) => {
      e.error(e);
      if (e.errorMessage) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (e.errorMessage.indexOf("AADB2C90118") > -1) {
          instance
            .loginPopup(b2cPolicies.authorities.forgotPassword)
            .then((response) => handlePolicyChange(response, instance));
        }
      }
    });
}

function handleLogout(instance: IPublicClientApplication) {
  instance.logoutPopup().catch((e) => {
    console.error(e);
  });
}

const SignInButton = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  return (
    <Button
      className={classes.accountButton}
      onClick={() => handleLogin(instance)}
    >
      <FaUserLock />
      {/* &nbsp;Sign in */}
    </Button>
  );
};

const SignOutButton = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  return (
    <Button
      className={classes.accountButton}
      onClick={() => handleLogout(instance)}
    >
      <FaLockOpen />
      {/* &nbsp;Sign out */}
    </Button>
  );
};

// interface IProps {}

export const AccountButton = (): JSX.Element => {
  // const styleProps: IProps = props;
  // const classes = useStyles(styleProps);
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  if (isAuthenticated) {
    const userId = accounts[0].localAccountId;
  }
  return isAuthenticated ? <SignOutButton /> : <SignInButton />;
  return <></>;
};
