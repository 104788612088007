import React, { useContext, useEffect, useState } from 'react';

import { createStyles, Link, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';

import { Footer, Header, Positioning } from '../components/pageParts';
import { ContentContext } from '../context/ContentContext';
import { TopicTypeEnum } from '../enums/TopicTypeEnum';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { ISystem } from '../interfaces/ISystem';
import Helpers from '../services/Helpers';
import { CapColors } from '../theme/CapColors';
import { CapCss } from '../theme/CapCss';
import { CapFonts } from '../theme/CapFonts';
import CapMuiTheme from '../theme/CapMuiTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    element: {
      display: "flex",
      justifyContent: "center",
    },
    headline: {
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
      textAlign: "center",
      fontSize: CapMuiTheme.typography.fontSize * 2,
      color: CapColors.text.body,
      fontWeight: CapFonts.Headline.weight,
      marginTop: CapMuiTheme.spacing(2),
      marginBottom: CapMuiTheme.spacing(2),
    },
    bodyText: {
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
      flex: 0.7,
      marginBottom: CapMuiTheme.spacing(2),
      fontSize: CapMuiTheme.typography.fontSize * 1.25,
      color: CapColors.text.body,
      fontWeight: "normal",
      textAlign: "left",
    },
    list: {
      flex: 0.7,
      fontSize: CapMuiTheme.typography.fontSize * 1.25,
      color: CapColors.text.body,
      fontWeight: "normal",
      marginTop: 0,
      marginRight: CapMuiTheme.spacing(4),
      marginBottom: CapMuiTheme.spacing(2),
      marginLeft: CapMuiTheme.spacing(4),
    },
    listItem: {
      marginBottom: CapMuiTheme.spacing(2),
    },
  })
);

interface IStyleProps {}

interface IProps {
  pageType: TopicTypeEnum;
}

// TODO: !!! Consolidate this page with AmpSkillupPage
export const TopicPage = (props: IProps): JSX.Element => {
  const styleProps: IStyleProps = {};
  const classes = useStyles(styleProps);
  const content = useContext(ContentContext);

  const [sysImage, setSysImage] = useState<string | null>();

  const posInfo: IPositioningInfo = {
    headline: props.pageType,
    body: [],
  };

  useEffect(() => {
    const initAsync = async () => {
      const systemsResponse = await content.getSystemsAsync();
      const sys = systemsResponse.find(
        (x: ISystem) => x.slug == "facilities-utilities"
      );
      const imgUrl = sys && sys.primaryImage && sys.primaryImage.mediaUrl;
      setSysImage(imgUrl);
    };
    initAsync();
  }, []);

  interface ISimpleBlock {
    label: string;
    link: string;
  }

  const SimpleBlock = (props: ISimpleBlock) => {
    return (
      <div className={classes.element} key={Helpers.uuid()}>
        <Typography className={classes.bodyText}>
          <b>{props.label}:</b>
          <br></br> <br></br>
          <Link href={`${props.link}`}>{props.link}</Link>
        </Typography>
      </div>
    );
  };

  interface IDisplayBlock {
    label: string;
    link: string;
    description: string;
  }

  const DisplayBlock = (props: IDisplayBlock) => {
    return (
      <div className={classes.element} key={Helpers.uuid()}>
        <Typography className={classes.bodyText}>
          <b>{props.label}:</b>
          <br></br>
          <br></br>
          <i>{props.description}</i>
          <br></br>
          <br></br>
          <Link href={`${props.link}`}>{props.link}</Link>
        </Typography>
      </div>
    );
  };

  if (props.pageType == TopicTypeEnum.excite) {
    return (
      <>
        <div className={classes.root}>
          <div className={classes.pagePartWrapper}>
            <Header image={sysImage} />
          </div>
          <div className={classes.pagePartWrapper} style={{ margin: 0 }}>
            <Positioning data={posInfo} />
          </div>
          <div className={classes.element} key={Helpers.uuid()}></div>
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Competency Model Clearinghouse<br></br>Advanced Manufacturing
              Industry Competency Model <br></br>
              CareerOneStop
            </Typography>
          </div>
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.bodyText}>
              The competency model framework for Advanced Manufacturing was
              developed through a collaborative effort involving the Employment
              and Training Administration (ETA) and leading industry
              organizations.<br></br>
              <br></br>{" "}
              <Link href={"https://www.careeronestop.org/"}>
                https://www.careeronestop.org
              </Link>
              <br></br> <br></br>
              <Link
                href={
                  "https://www.careeronestop.org/CompetencyModel/competency-models/advanced-manufacturing.aspx"
                }
              >
                https://www.careeronestop.org/CompetencyModel/competency-models/advanced-manufacturing.aspx
              </Link>
            </Typography>
          </div>
          <Footer />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={classes.root}>
          <div className={classes.pagePartWrapper}>
            <Header image={sysImage} />
          </div>
          <div className={classes.pagePartWrapper} style={{ margin: 0 }}>
            <Positioning data={posInfo} />
          </div>
          <div className={classes.element} key={Helpers.uuid()}></div>
          {/*  */}
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Certificate Education
            </Typography>
          </div>
          {/*  */}
          <SimpleBlock
            label="North East Advanced Technological Education Center"
            link="https://neatec.org"
          />
          <SimpleBlock
            label="AMP Skillup America (SUNY Polytechnic Institute)"
            link="http://amp.skillupamerica.org"
          />
          {/*  */}
          <DisplayBlock
            label="Semiconductor Technology (Hudson Valley Community College)"
            link="https://www.hvcc.edu/programs/all/stem/semiconductor-technology-certificate.html"
            description="Salaries vary based on position and degree attainment. Here is the median salary taken from the Bureau of Labor Statistics (as of 2017): Semiconductor Processors, $38,850."
          />
          {/*  */}
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Associates Degree Education
            </Typography>
          </div>
          {/*  */}
          <SimpleBlock
            label="AAS Semiconductor Manufacturing Technology (Hudson Valley Community College)"
            link="https://www.hvcc.edu/programs/all/stem/electrical-technology-semiconductor-manufacturing-technology-aas.html"
          />
          {/*  */}
          <DisplayBlock
            label="Electrical Technology: Semiconductor Manufacturing Technology | HVCC"
            link="https://www.hvcc.edu"
            description="This degree can prepare you to enter emerging fields such as semiconductor fabrication,
micro-electro-mechanical systems and nanotechnology. You will benefit from both
classroom education and hands-on instruction."
          />
          {/*  */}
          <SimpleBlock
            label="AAS Semiconductor Manufacturing Technology (Mohawk Valley Community College)"
            link="https://www.mvcc.edu/academics/stem/semiconductor-manufacturing/index.php"
          />
          {/*  */}
          <DisplayBlock
            label="AAS Mechatronics (Hudson Valley Community College)"
            link="https://www.hvcc.edu/programs/all/stem/mechatronics-aas.html"
            description=" Graduates will understand the various components, devices and machines used in modern automation systems along with a study of their related electronics that include pneumatics, control signal types, control valves, transducers, industrial communication networks, variable frequency drives (VFD), programmable logic controllers (PLC), operator interface terminals (OIT) and HMI/SCADA systems and ..."
          />
          {/*  */}
          <DisplayBlock
            label="AAS Electrical Engineering Technology (Hudson Valley Community College)"
            link="https://www.hvcc.edu/programs/all/stem/electrical-engineering-technology-electronics-aas.html"
            description="This degree is designed to meet the demand for employees with electronics skills.
You'`ll learn electrical and electronic theories and how to apply those theories in
a laboratory setting."
          />
          {/*  */}
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Bachelor Degree Education
            </Typography>
          </div>
          {/*  */}
          <SimpleBlock
            label="BS Nanoscale Engineering (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/nanoscale-engineering.html"
          />
          {/*  */}
          <DisplayBlock
            label="Nanoscale Engineering | SUNY Polytechnic Institute"
            link="https://sunypoly.edu/academics/majors-and-programs/nanoscale-engineering.html"
            description="SUNY Poly&#039;s undergraduate program in Nanoscale Engineering uniquely prepares
                                students for growing scientific opportunities in the nanotechnology-enabled careers of
                                the 21st century."
          />
          {/*  */}
          <DisplayBlock
            label="BS Nanoscale Science (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/nanoscale-science.html"
            description="SUNY Poly&#039;s bachelor&#039;s degree in nanoscale science, features a cutting-edge,
                                interdisciplinary, and truly innovative instructional portfolio centered on scholarly
                                excellence that taps into our international academic leadership in nanoscale science."
          />
          {/*  */}
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Masters Degree Education
            </Typography>
          </div>
          {/*  */}
          <DisplayBlock
            label="MS Advanced Technology (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/ms-advanced-technology.html"
            description="The M.S. in Advanced Technology is a course-based, advanced degree program designed for
                                professionals who want to expand their knowledge, learn new skills, and advance their
                                careers in the semiconductor and other advanced manufacturing industries. Focusing on
                                the enabling technologies for semiconductor processing and nanomanufacturing, this
                                pioneering program uses an applied learning approach that is centered on cutting edge
                                patterning, fabrication, metrology, and device characterization methods for current and
                                next-generation high volume manufacturing of nanoelectronics."
          />
          {/*  */}
          <DisplayBlock
            label="MS Nanoscale Engineering (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/ms-nanoscale-engineering.html"
            description="       Our Nanoscale Engineering graduate programs provide students with skill and expertise in
                                the design, fabrication, and integration of nanoscale devices, structures, and systems."
          />
          {/*  */}
          <DisplayBlock
            label="MS Nanoscale Science (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/ms-nanoscale-science.html"
            description="Our Nanoscale Science M.S. program provide the critical theoretical and experimental skill base and know-how for knowledge creation in the areas of nanoscale materials, structures, and architectures."
          />
          {/*  */}
          <DisplayBlock
            label="MBA Technology Management (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/technology-management.html"
            description="SUNY Poly offers a highly ranked online MBA that is unique in its distinction in technology management, designed to prepare students for management and leadership roles in leveraging technologies to develop competitive advantages in business strategies and operations."
          />
          {/*  */}
          <div className={classes.element} key={Helpers.uuid()}>
            <Typography className={classes.headline}>
              Doctoral Degree Education
            </Typography>
          </div>
          {/*  */}

          <DisplayBlock
            label="SePhD Nanoscale Engineering (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/phd-nanoscale-engineering.html"
            description="Our Nanoscale Engineering Ph.D. program provides students with skill and expertise in the design, fabrication, and integration of nanoscale devices, structures, and systems."
          />
          {/*  */}
          <SimpleBlock
            label="PhD Nanoscale Science (SUNY Polytechnic Institute)"
            link="https://sunypoly.edu/academics/majors-and-programs/phd-nanoscale-science.html"
          />
          {/*  */}
          <Footer />
        </div>
      </>
    );
  }
};
