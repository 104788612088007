import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';

import { createStyles, Link, makeStyles, Theme, useTheme } from '@material-ui/core';

import { Footer, Header, Positioning } from '../components/pageParts';
import { ContentContext } from '../context/ContentContext';
import { IContent } from '../interfaces/IContent';
import { IPositioningInfo } from '../interfaces/IPositioningInfo';
import { CapColors } from '../theme/CapColors';
import { CapCss } from '../theme/CapCss';
import { CapFonts } from '../theme/CapFonts';
import CapMuiTheme from '../theme/CapMuiTheme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: CapCss.root,
    pagePartWrapper: CapCss.pagePartWrapper,
    itemWrapper: {
      display: "flex",
      // flexDirection: 'column',
      marginTop: CapMuiTheme.spacing(1),
      marginBottom: CapMuiTheme.spacing(5),
    },
    imageItem: {
      borderStyle: "solid",
      borderWidth: CapMuiTheme.spacing(0.1),
      borderColor: CapColors.text.body,
      width: "100%",
      // maxHeight: '660px',
      objectFit: "cover",
      margin: CapMuiTheme.spacing(2),
    },
    videoItem: {
      width: "100%",
      // maxHeight: '660px',
      objectFit: "cover",
      margin: CapMuiTheme.spacing(2),
    },
    headline: {
      color: CapColors.text.body,
      fontFamily: CapFonts.Headline.face,
      fontWeight: CapFonts.Headline.weight,
      fontSize: CapMuiTheme.typography.fontSize * 2,
      paddingRight: CapMuiTheme.spacing(2),
      paddingLeft: CapMuiTheme.spacing(2),
      [theme.breakpoints.up("xs")]: {},
      [theme.breakpoints.up("sm")]: {},
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.up("xl")]: {},
    },
  })
);

type ParamsType = {
  concentrationId: string;
  concentrationSlug: string;
  contentId: string;
  contentSlug: string;
  courseId: string | undefined;
  courseSlug: string | undefined;
  focusId: string;
  focusSlug: string;
  systemSlug: string;
};

export enum ViewerMode {
  NotSet,
  Image,
  Video,
}

export const ContentDisplayPage = (): JSX.Element => {
  const params = useParams<ParamsType>();
  const classes = useStyles();
  const theme = useTheme();
  const content = useContext(ContentContext);
  const history = useNavigate();

  const [title, setTitle] = useState("");
  const [viewMode, setViewMode] = useState<ViewerMode>(ViewerMode.NotSet);
  const [contentItem, setContentItem] = useState<IContent>();

  useEffect(() => {
    const initAsync = async () => {
      const startupConcentration = await content.getConcentrationContentAsync(
        params.concentrationId ?? ""
      );
      const startupFocus = startupConcentration?.focuses?.find(
        (f) => f.slug === params.focusSlug
      );
      let startupContent: IContent | undefined;
      console.log("ContentDisplayPage", params);

      // if (params.courseId) {
      //     const course = startupFocus?.courses?.find((x) => x.content?.find((y) => y.sys.id == params.contentId));
      //     startupContent = course?.content?.find((x) => x.sys.id == params.contentId);
      // } else {
      startupContent = startupFocus?.content?.find(
        (c) => c.sys.id === params.contentId
      );
      if (!startupContent) {
        const course = startupFocus?.courses?.find((x) =>
          x.content?.find((y) => y.sys.id == params.contentId)
        );
        startupContent = course?.content?.find(
          (x) => x.sys.id == params.contentId
        );
      }
      // }
      setContentItem(startupContent);
      const pageTitle = `${startupContent?.displayName}`; // `${startupConcentration?.displayName} > ${startupFocus?.displayName} > ${startupContent?.displayName}`;
      setTitle(pageTitle);
      const contentType = startupContent?.mediaSelection?.file.contentType;
      const isVideo = contentType ? contentType.match(/video/) != null : false;
      // const isImage = contentType ? contentType.match(/image/) != null : false;
      setViewMode(isVideo ? ViewerMode.Video : ViewerMode.Image);
    };
    initAsync();
  }, []);

  const posInfo: IPositioningInfo = {
    headline: title,
    body: [],
  };

  const renderContent = (mode: ViewerMode, item: IContent | undefined) => {
    const imgUrl = item?.mediaSelection?.file.url;
    let element = <></>;
    switch (mode) {
      case ViewerMode.NotSet:
        element = <div>SPINNER</div>;
        break;
      case ViewerMode.Video:
        element = (
          <ReactPlayer
            url={imgUrl}
            className={classes.videoItem}
            playing={false}
            controls={true}
          />
        );
        break;
      case ViewerMode.Image:
        element = <img className={classes.imageItem} src={imgUrl}></img>;
        break;
    }
    return <div className={classes.itemWrapper}>{element}</div>;
  };

  const renderBackButton = (history: any) => {
    return (
      <>
        {/* TODO: CHANGE TO AN ACTUAL URL IN CASE COMING FROM AN OFFSITE LINK/BOOKMARK */}
        <Link href={"#"} onClick={() => history.goBack()}>
          Back
        </Link>
      </>
    );
  };

  return (
    <>
      <div className={classes.root}>
        <div
          className={classes.pagePartWrapper}
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Header />
        </div>
        <div
          style={{
            marginTop: theme.spacing(10),
            marginLeft: theme.spacing(10),
            marginBottom: theme.spacing(0),
          }}
        >
          {renderBackButton(history)}
        </div>
        <div className={classes.pagePartWrapper} style={{ margin: 0 }}>
          <Positioning data={posInfo} />
        </div>
        <div className={classes.pagePartWrapper} style={{ margin: 0 }}>
          {renderContent(viewMode, contentItem)}
        </div>
        <Footer />
      </div>
    </>
  );
};
